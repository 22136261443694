import {Box, Button, Chip, CircularProgress, IconButton, Tooltip} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.module.scss';
import ClearIcon from '@mui/icons-material/Clear';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import AddLabelDialog from '../../dialogs/add-label-dialog';
import {PropertyLabels, PropertyLabelsType, PublicationStatus} from '../../../../types';
import Loader from '../../loader';
import {useFormWatcherContext} from '../../../../contexts/FormWatcherProvider';
import {ReactComponent as Save} from 'svg-icons/save.svg';
import {useLocation, useParams} from 'react-router-dom';
import {PROPERTY_URL} from '../../../../constants/routes';
import Actions from '../../actions';
import {useToastContext} from '../../../../contexts/ToastContext';

const PropertyLayout: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
  labels: PropertyLabels[];
  loading?: boolean;
  formRef: any;
  setRenderLabels: Dispatch<SetStateAction<PropertyLabels[]>>;
  type: 'edit' | 'create';
  refetch: () => void;
  isFetching: boolean;
  publicationStatus: PublicationStatus;
}> = ({
  children,
  title,
  labels = [],
  loading = false,
  formRef,
  setRenderLabels,
  type,
  refetch,
  isFetching,
  publicationStatus
}) => {
  const {setFormContextChanged} = useFormWatcherContext();
  const [open, setOpen] = useState(false);
  const {isFormContextChanged} = useFormWatcherContext();
  const location = useLocation();
  const isBase = location.pathname.includes(PROPERTY_URL);
  const {id: propertyId} = useParams();
  const {filter: filterName = ''} = useParams();
  const {setToastData, setOpenToast} = useToastContext();

  const handleDelete = (labelId: string) => {
    setRenderLabels((state) => {
      return state.filter((item) => item.id !== labelId);
    });
    setFormContextChanged(true);
  };

  const onAddLabelClick = () => {
    setOpen(true);
  };

  const onSaveSubmit = async (data: any) => {
    await formRef?.current?.submit();
    const isInvalid = formRef?.current && Object.keys(formRef?.current?.formState?.errors).length > 0;
    if (isInvalid) {
      setToastData({
        toastBody: 'Невірно заповнені поля форми',
        toastTitle: 'Помилка',
        toastType: 'error'
      });
      setOpenToast(true);
    }
  };

  useEffect(() => {
    setRenderLabels(labels);
  }, [labels.length]);

  const getParsedPropertyActions = () => {
    const hasTrashLabel = labels.filter((item) => item.label.alias === PropertyLabelsType.TRASH);
    const hasPostPonedLabel = labels.filter((item) => item.label.alias === PropertyLabelsType.POSTPONED);
    const rawArray = [
      !hasTrashLabel.length && 'trash',
      !hasPostPonedLabel.length && 'postponed',
      'putOff',
      type !== 'create' && 'merge'
    ];
    return rawArray.filter(Boolean);
  };

  const getPropertyActions = () => {
    switch (filterName) {
      case 'active':
        return ['trash', 'archive'];
      case 'unpublished':
        if (publicationStatus === PublicationStatus.UNPUBLISHED) {
          return ['trash', 'doNotPublish', 'published', 'archive'];
        }
        if (publicationStatus === PublicationStatus.DO_NOT_PUBLISH) {
          return ['trash', 'archive'];
        }
        return ['trash', 'doNotPublish', 'archive'];
      default:
        return ['trash', 'revivedFromArchive'];
    }
  };

  const actionsList = isBase ? getPropertyActions() : getParsedPropertyActions();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{width: '100%'}}>
            <Box
              sx={{
                bgcolor: 'background.label2'
              }}
              className={styles.wrapper}
            >
              {title}
              <Box
                sx={{
                  display: 'flex',
                  gap: '12px',
                  flexShrink: '0',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                  justifyContent: 'flex-end'
                }}
              >
                {propertyId && actionsList && actionsList?.length && (
                  <Actions
                    //@ts-ignore
                    actionList={actionsList}
                    propertyId={propertyId}
                    refetch={refetch}
                    formChanged={isFormContextChanged}
                    size={'medium'}
                    redirectAfterMerge={true}
                  />
                )}
                <Tooltip disableInteractive title={!isFormContextChanged ? 'Внесіть зміни' : ''} placement="top" arrow>
                  <span>
                    <Button
                      variant="contained"
                      onClick={onSaveSubmit}
                      className={styles.btn}
                      disabled={!isFormContextChanged || isFetching}
                    >
                      {isFetching ? <CircularProgress size={'24px'} /> : <Save />}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box
                className={styles.shadow}
                sx={{
                  bgcolor: 'background.label2'
                }}
              />
            </Box>
            {type !== 'create' && !isBase && (
              <Box
                sx={{
                  bgcolor: 'background.label2'
                }}
                className={styles.inner}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexShrink: '0',
                    flexWrap: 'wrap',
                    width: '100%'
                  }}
                >
                  {!!labels &&
                    labels.map((label, index) => (
                      <Chip
                        key={`chip_${index}`}
                        label={label.label.name}
                        onDelete={() => {
                          handleDelete(label.id);
                        }}
                        sx={{
                          bgcolor: `background.default`,
                          borderRadius: '4px'
                        }}
                        variant="outlined"
                        deleteIcon={
                          <IconButton sx={{padding: 0}}>
                            <ClearIcon />
                          </IconButton>
                        }
                      />
                    ))}
                  <Button
                    variant={'contained'}
                    size={'small'}
                    sx={{
                      textTransform: 'none',
                      color: 'primary.main',
                      bgcolor: 'background.label'
                    }}
                    onClick={onAddLabelClick}
                  >
                    Додати лейбу
                    <AddIcon fontSize={'small'} />
                  </Button>
                </Box>
                <Box
                  className={styles.shadow}
                  sx={{
                    bgcolor: 'background.label2'
                  }}
                />
              </Box>
            )}
            <Box>{children}</Box>
          </Box>
          <AddLabelDialog open={open} setOpen={setOpen} onAddLabel={setRenderLabels} propertyLabels={labels} />
        </>
      )}
    </>
  );
};

export default PropertyLayout;
