import styles from './styles.module.scss';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContentText, IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {DateTimePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useQuery} from 'react-query';
import {getPublishTemplate, postPublication, repostPublication} from '../../../../api/base';
import {useToastContext} from '../../../../contexts/ToastContext';
import Gallery from './gallery';
import {formatDateString} from '../../../../helpers';
import {usePublicationsContext} from '../../../../contexts/PublicatiosContext';
import {CloseIcon} from 'yet-another-react-lightbox';
import {useLocation, useNavigate} from 'react-router-dom';
import {PROPERTY_URL} from '../../../../constants/routes';

const Publish: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
  isRepost: boolean;
}> = ({setOpen, open, propertyId, refetch, isRepost}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const {setRefetchTrigger} = usePublicationsContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const isObjectPage = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const type = pathSegments[pathSegments.length - 2];
    const id = pathSegments[pathSegments.length - 1];
    return (type === 'unpublished') && !isNaN(Number(id));
  };
  const getDefaultValues = () => {
    return isRepost
      ? {
          scheduled_at: dayjs().add(15, 'minutes'),
          postponed: false,
          contacted_with_owner: false
        }
      : {
          scheduled_at: dayjs().add(15, 'minutes'),
          postponed: false
        };
  };

  const {control, watch, reset, handleSubmit, getValues} = useForm({
    defaultValues: getDefaultValues()
  });

  const {data, isLoading} = useQuery(
    'publication/template',
    async () => {
      return await getPublishTemplate({id: propertyId});
    },
    {
      retry: false,
      enabled: !!propertyId
    }
  );

  const isPostponed = watch('postponed', false);
  const repostConfirm = watch('contacted_with_owner', false);

  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  const isContentEmpty = !editorState.getCurrentContent().hasText();

  const onSubmit = async (data: Record<string, any>) => {
    setLoading(true);
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/target="[^"]*"/g, '');
    const submitFn = isRepost ? repostPublication : postPublication;
    const getFormatedData = () => {
      return isRepost
        ? {
            postponed: data.postponed,
            contacted_with_owner: data.contacted_with_owner,
            post_content: content,
            ...(data.scheduled_at &&
              data.postponed && {
                scheduled_at: formatDateString(data.scheduled_at, 'YYYY-MM-DD HH:mm:00')
              })
          }
        : {
            postponed: data.postponed,

            post_content: content,
            ...(data.scheduled_at &&
              data.postponed && {
                scheduled_at: formatDateString(data.scheduled_at, 'YYYY-MM-DD HH:mm:00')
              })
          };
    };

    const response = await submitFn(propertyId, getFormatedData());

    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });

    setOpenToast(true);
    if (!response?.success) {
      return;
    }
    setEditorState(EditorState.createEmpty());
    reset();
    const trigger = Date.now();
    setRefetchTrigger(trigger.toString());
    setLoading(false);
    setOpen(false);

    if (isObjectPage()) {
      navigate(`${PROPERTY_URL}/active/${propertyId}`, { replace: true });
    }

    refetch();

  };

  useEffect(() => {
    if (data?.success || !data) {
      return;
    }
    setToastData({
      toastBody: data?.message || '',
      toastTitle: 'Помилка',
      toastType: 'error'
    });
    setOpenToast(true);
  }, [data, setOpenToast, setToastData]);

  useEffect(() => {
    if (!data?.template) {
      return;
    }

    const blocksFromHTML = htmlToDraft(data.template);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(contentState));
  }, [data?.template]);

  const isClear = isRepost ? !repostConfirm || isContentEmpty || loading : isContentEmpty || loading;

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
        scroll={'paper'}
      >
        <DialogContentText className={styles.scroll}>
          <Paper component={'form'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '24px'
              }}
            >
              <Typography variant={'h5'}>
                <strong>Публікація обʼєкту</strong>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              className={classNames(styles.comment, styles.editor)}
              sx={{borderColor: 'dark.secondary', bgcolor: 'background.secondary'}}
            >
              {isLoading ? (
                <CircularProgress size={'32px'} sx={{margin: 'auto'}} />
              ) : (
                <Editor
                  editorState={editorState}
                  wrapperClassName="postEditorContainer"
                  editorClassName="postEditor"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'link'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                      bold: {icon: '/bold.svg', className: ''},
                      italic: {icon: '/italic.svg', className: ''},
                      underline: {icon: '/underline.svg', className: ''}
                    },
                    link: {
                      options: ['link'],
                      link: {icon: '/link.svg', className: ''}
                    }
                  }}
                />
              )}
            </Box>
            {!isLoading && (
              <Box className={classNames(styles.comment)}>
                <Controller
                  control={control}
                  render={({field, fieldState, formState}) => {
                    return (
                      <label>
                        <Checkbox
                          sx={{width: '15px', height: '15px', marginRight: '12px'}}
                          {...field}
                          checked={field.value}
                        />
                        <Typography variant={'h6'}>Відкладена публікація</Typography>
                      </label>
                    );
                  }}
                  name={'postponed'}
                />
              </Box>
            )}
            <Box className={classNames(styles.comment, {[styles.hidden]: !isRepost})}>
              <Controller
                control={control}
                render={({field, fieldState, formState}) => {
                  return (
                    <label className={styles.checkbox}>
                      <Checkbox {...field} />
                      <Typography variant={'caption'}>Я зв'язався з власником об'єкту *</Typography>
                    </label>
                  );
                }}
                name={`contacted_with_owner`}
              />
            </Box>
            <Box className={classNames(styles.comment, styles.timeWrapper)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isPostponed && (
                  <Controller
                    name="scheduled_at"
                    control={control}
                    render={({field}) => (
                      <DateTimePicker
                        {...field}
                        label=""
                        value={field.value}
                        onChange={(newValue) => field.onChange(newValue)}
                        ampm={false}
                        format="DD.MM.YY HH:mm"
                        timeSteps={{minutes: 15}}
                        minDate={dayjs()}
                        minTime={dayjs().add(5, 'minutes')}
                      />
                    )}
                  />
                )}
              </LocalizationProvider>
            </Box>
            {data?.media && (
              <Box className={classNames(styles.comment, styles.timeWrapper)}>
                <Gallery media={data?.media} />
              </Box>
            )}
            <Box className={styles.btns}>
              <Button
                type={'button'}
                variant="text"
                onClick={() => {
                  setEditorState(EditorState.createEmpty());
                  reset();
                  setOpen(false);
                }}
              >
                <strong>Скасувати</strong>
              </Button>
              <Tooltip disableInteractive title={isContentEmpty && !loading && 'Заповніть поле'} arrow>
                <span>
                  <Button
                    className={styles.button}
                    variant={'contained'}
                    color={'primary'}
                    type={'button'}
                    onClick={handleSubmit(onSubmit)}
                    disabled={isClear}
                  >
                    {loading ? (
                      <CircularProgress size={'24px'} />
                    ) : (
                      <strong>{isRepost ? 'Репост' : 'Опублікувати'}</strong>
                    )}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Paper>
        </DialogContentText>
      </Dialog>
    </>
  );
};

export default Publish;
