import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import styles from './styles.module.scss';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import {Controller} from 'react-hook-form';
import {getErrorMessage} from '../../../helpers';

const AutocompliteWithDropdown = ({
  control,
  label,
  name,
  placeholder = '',
  size = '',
  items = [
    {
      label: '',
      value: ''
    }
  ],
  startAdornmentItems = [
    {
      label: '',
      value: ''
    }
  ],
  showEndAdornment = false,
  onChange = () => {},
  register = null,
  rules = {},
  multiple = false
}) => {
  const StartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start" className={classNames(styles.wrapper)}>
        <Controller
          control={control}
          render={({field, fieldState, formState}) => {
            const errorMessage = getErrorMessage(name, formState.errors);
            return (
              <Select
                {...field}
                variant={'filled'}
                {...(!!errorMessage ? {sx: {color: 'background.error', ml: 0}} : {})}
              >
                {startAdornmentItems?.map((item, index) => (
                  <MenuItem key={`item_${index}`} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
          name={`adornment_${name}`}
        />
      </InputAdornment>
    );
  }, [items]);
  const EndAdornment = useMemo(() => {
    if (!showEndAdornment) {
      return null;
    }
    return (
      <InputAdornment position="end" className={classNames(styles.wrapper)}>
        <Typography noWrap={true} variant={'body2'} sx={{maxWidth: '55px'}}>
          40000
        </Typography>
      </InputAdornment>
    );
  }, []);

  return (
    <FormControl fullWidth control={control} name={name} className={classNames(styles[size])}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        rules={rules}
        render={({field, fieldState, formState}) => {
          const currentValue = multiple ? field.value || [] : field.value;
          const errorMessage = getErrorMessage(name, formState.errors);

          return (
            <>
              <Autocomplete
                freeSolo
                {...field}
                multiple={multiple}
                disableClearable={true}
                options={multiple ? items : items.map((option) => option?.label)}
                value={currentValue}
                getOptionLabel={(option) => (multiple ? option.label : option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    error={!!errorMessage}
                    endAdornment={EndAdornment}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      onChange: (event) => {
                        if (multiple) {
                          return;
                        }
                        field.onChange(event);
                        if (onChange) onChange(name, event.target.value);
                      },
                      startAdornment: StartAdornment
                    }}
                  />
                )}
                onChange={(event, newValue) => field.onChange(newValue)}
              />
              {errorMessage && (
                <FormHelperText sx={{color: 'error.main'}} id="outlined-weight-helper-text">
                  <>{errorMessage['0'] || errorMessage?.message || errorMessage}</>
                </FormHelperText>
              )}
            </>
          );
        }}
        name={name}
      />
    </FormControl>
  );
};

export default AutocompliteWithDropdown;
