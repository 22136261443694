import {Box, Typography, useTheme} from '@mui/material';
import styles from './styles.module.scss';
import SelectDropdown from '../../selectDropdown';
import React, {useCallback, useMemo, useState} from 'react';
import {FormFields, IAdmin, PropertyAdmin} from '../../../../types';
import {API_ROUTES, propertyStatusNames, publicationStatusNames} from '../../../../constants';
import {debounce, getNormalisedOptions} from '../../../../helpers';
import {getSearchAdmins, getStreetStreets} from '../../../../api/filters';
import {useQuery} from 'react-query';
import AutocompleteInput from '../../autocompleteInput';

const Status: React.FC<{
  control: any;
  register: any;
  isParser: boolean;
}> = ({control, register, isParser}) => {
  const theme = useTheme();

  const [name, setName] = useState<null | string>(null);

  const {data: admins = [] as PropertyAdmin[], isLoading: isAdminsLoading} = useQuery(
    [API_ROUTES.ADMINS, name],
    () => getSearchAdmins({only_active: false, name: name || ''}),
    {
      retry: false,
      refetchOnMount: false,
      enabled: name !== null
    }
  );

  const onChange = useCallback(
    debounce((name: string, value: string) => {
      setName(value?.toLowerCase());
    }, 300),
    []
  );

  const onFocus = () => {
    if (name !== null) {
      return;
    }
    setName('');
  };

  return (
    <Box className={styles.wrapper}>
      {isParser ? (
        <Box className={styles.row}>
          <Typography
            //@ts-ignore
            variant={'captionSmall'}
          >
            Асайн
          </Typography>
          <Box sx={{width: '100%', maxWidth: '230px'}}>
            <AutocompleteInput
              label={''}
              name={'assignee'}
              onChange={onChange}
              items={
                // @ts-ignore
                admins.map((admin) => ({
                  label: `${admin.first_name} ${admin.last_name}`,
                  value: admin.id
                }))
              }
              control={control}
              register={register}
              placeholder={'Виберіть адміна'}
              size={'medium'}
              multiple={false}
              onInputFocus={onFocus}
              isLoading={isAdminsLoading}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box className={styles.row}>
            <Typography
              //@ts-ignore
              variant={'captionSmall'}
            >
              Статус
            </Typography>
            <Box sx={{width: '100%', maxWidth: '230px'}}>
              <SelectDropdown
                label={''}
                disabled={true}
                name={'status'}
                onChange={() => {}}
                items={Object.entries(propertyStatusNames).map(([key, value]) => ({
                  value: key,
                  label: value
                }))}
                control={control}
                register={register}
                placeholder={'placeholder'}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    //@ts-ignore
                    borderColor: theme.palette.background.blue
                  }
                }}
                size={''}
              />
            </Box>
            <Typography
              //@ts-ignore
              variant={'captionSmall'}
              sx={{
                flexShrink: 0,
                ml: '12px'
              }}
            >
              Статус публікації
            </Typography>
            <Box sx={{width: '100%', maxWidth: '230px'}}>
              <SelectDropdown
                label={''}
                disabled={true}
                name={FormFields.publication_status}
                onChange={() => {}}
                items={getNormalisedOptions(publicationStatusNames)}
                control={control}
                register={register}
                placeholder={'placeholder'}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    //@ts-ignore
                    borderColor: theme.palette.background.blue
                  }
                }}
                size={''}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Status;
