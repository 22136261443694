import {useEffect, useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {useSearchParams, useParams, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import PropertyItem from '../../common/listItem';
import ParsersContentLayout from '../../Layouts/parsersContentLayout';
import Pagination from 'components/common/pagination';
import {useUser} from 'hooks/useUser';
import {getParsedProperties} from 'api/properties';
import Loader from 'components/common/loader';
import {useFilterContext} from '../../../contexts/FiltersContext';
import {PropertyListItemType} from '../../../types';
import NoData from '../../common/no-data';
import {PARSER_BASE_URL} from '../../../constants/routes';
import {defaultPropertiesFilterValues} from '../../../constants';

type IFilter = Record<string, {title: string; search: Record<string, any>}>;

const ParsedPropertyListPage = () => {
  const {user} = useUser();
  const {filters, setFilterErrors, setFilters} = useFilterContext();
  const propertyFilterConfig: IFilter = useMemo(
    () => ({
      all: {
        title: "Мої об'єкти",
        search: user?.id
          ? {
              assignee_id: user?.id,
              excluded_labels: ['trash'],
              ...filters
            }
          : {}
      },
      new: {
        title: "Всі нові об'єкти",
        search: {excluded_labels: ['trash'], ...filters}
      },
      owners: {
        title: 'Оголошення від власників',
        search: {excluded_labels: ['trash'], labels: ['maybe_owner', 'owner'], ...filters}
      },
      'price-higher': {
        title: 'Ціна виросла',
        search: {excluded_labels: ['trash'], labels: ['price_higher'], ...filters}
      },
      'price-lower': {
        title: 'Ціна знизилась',
        search: {excluded_labels: ['trash'], labels: ['price_lower'], ...filters}
      },
      postponed: {
        title: 'Відкладені оголошення',
        search: {excluded_labels: ['trash'], labels: ['postponed'], ...filters}
      },
      'revived-from-archive': {
        title: 'Воскреслі з архіву',
        search: {excluded_labels: ['trash'], labels: ['revived_from_archive'], ...filters}
      },
      trash: {
        title: 'Смітник',
        search: {labels: ['trash'], ...filters}
      }
    }),
    [user, filters]
  );

  const {filter: filterName = ''} = useParams();
  const navigate = useNavigate();
  const filter: {title: string; search: Record<string, any>} = propertyFilterConfig[filterName];

  const pageSearchParams = filter.search;

  const title = filter?.title;
  let [searchParams] = useSearchParams();
  const cursor = searchParams.get('cursor') || '';

  const {
    data: resultData,
    isLoading,
    isSuccess,
    refetch,
    error
  } = useQuery(
    [pageSearchParams, cursor],
    () =>
      getParsedProperties({
        cursor,
        ...pageSearchParams
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (!filter) {
      navigate(PARSER_BASE_URL);
    }
  }, [filter, navigate]);

  useEffect(() => {
    if (!error) {
      return;
    }
    // @ts-ignore
    setFilterErrors(error.response?.data?.errors);
  }, [error]);

  useEffect(() => {
    return () => {
      setFilters(defaultPropertiesFilterValues);
    };
  }, []);

  const data: PropertyListItemType[] = resultData?.data || [];
  const isSearchFinishedAndResultsEmpty = useMemo(() => isSuccess && !data.length, [data.length, isSuccess]);

  return (
    <ParsersContentLayout title={title} isLoading={isLoading}>
      <>
        {isSearchFinishedAndResultsEmpty && <NoData isLoading={false} />}
        {isLoading && <Loader size={'32px'} />}

        {!isSearchFinishedAndResultsEmpty && !isLoading && (
          <>
            <Grid container spacing={1} sx={{py: 4}}>
              {Array.isArray(data) &&
                data?.map((itemDetails: PropertyListItemType) => {
                  return (
                    <PropertyItem
                      item={itemDetails}
                      key={itemDetails.id}
                      filter={filter}
                      refetch={refetch}
                      parentList={'parser'}
                    />
                  );
                })}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pb: '2rem'
              }}
            >
              <Pagination cursor={resultData?.cursor} />
            </Box>
          </>
        )}
      </>
    </ParsersContentLayout>
  );
};

export default ParsedPropertyListPage;
