import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {PublicationStatus} from '../types';

export interface IPublcationPropperty {
  id: string | null;
  publication_status: PublicationStatus;
  archived: boolean;
}

interface PublicationsContextType {
  property: Record<string, any>;
  setProperty: Dispatch<SetStateAction<Record<string, any>>>;
  refetchTrigger: string;
  setRefetchTrigger: Dispatch<SetStateAction<string>>;
}

const PublicationsContext = createContext<PublicationsContextType>({
  property: {},
  setProperty: () => {},
  refetchTrigger: '',
  setRefetchTrigger: () => {}
});

export const PublicationsContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [property, setProperty] = useState({});
  const [refetchTrigger, setRefetchTrigger] = useState('');

  return (
    <PublicationsContext.Provider value={{property, setProperty, refetchTrigger, setRefetchTrigger}}>
      {children}
    </PublicationsContext.Provider>
  );
};

export const usePublicationsContext = () => {
  const context = useContext(PublicationsContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
