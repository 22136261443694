import React from 'react';
import {Box} from '@mui/material';
import styles from './styles.module.scss';
import {PropertyAdmin, PropertyStatus} from 'types';
import Actions from '../../actions';
import {propertyStatusNames} from '../../../../constants';
import Assignee from './assignee';
import classNames from 'classnames';

const ItemActions: React.FC<{
  actionList: any;
  propertyId: string;
  assignee: PropertyAdmin | null;
  refetch: any;
  parentList: 'base' | 'parser';
  propertyStatus?: PropertyStatus;
}> = ({actionList, propertyId, assignee, refetch, parentList, propertyStatus}) => {
  console.log('parentList', parentList);
  return (
    <>
      <Box className={styles.wrapper}>
        {parentList === 'base' && propertyStatus && (
          <Box
            className={styles.label}
            sx={{bgcolor: 'background.default', borderColor: 'background.info', color: 'background.info'}}
          >
            {propertyStatusNames[propertyStatus]}
          </Box>
        )}
        <div className={styles.relative}>
          <Box
            sx={{bgcolor: 'background.default', borderColor: 'background.info', color: 'background.info'}}
            className={classNames(styles.dropdown)}
          >
            <Assignee assignee={assignee} propertyId={propertyId} disabled={parentList === 'base'} />
          </Box>
        </div>

        <Box className={styles.row}>
          <Actions actionList={actionList} propertyId={propertyId} refetch={refetch} />
        </Box>
      </Box>
    </>
  );
};

export default ItemActions;
