import {Autocomplete, FormControl, FormHelperText, TextField, Typography} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import {Control, Controller} from 'react-hook-form';
import {ISelectItem} from 'types';
import {getErrorMessage} from 'helpers';
import CircularProgress from '@mui/material/CircularProgress';

const AutocompleteInput: React.FC<{
  control: Control<Record<string, any>>;
  name: string;
  label: string;
  size: string;
  placeholder: string;
  onChange?: (name: string, values: any) => void;
  onInputFocus?: () => void;
  items: ISelectItem[];
  multiple: boolean;
  rules?: Record<string, any>;
  register?: any;
  isLoading?: boolean;
}> = ({
  control,
  name,
  label,
  size = '',
  placeholder = '',
  onChange = () => {},
  items = [],
  multiple = false,
  rules = {},
  onInputFocus = () => {},
  isLoading = false
}) => {
  const endAdornment = isLoading ? {endAdornment: <CircularProgress size={'20px'} />} : {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState}) => {
        const currentValue = multiple ? field.value || [] : field.value?.label || field.value;
        const errorMessage = getErrorMessage(name, formState.errors);

        return (
          <FormControl sx={{width: '100%'}} className={styles[size]}>
            <>
              {label ? (
                <Typography variant="caption" gutterBottom>
                  {label}
                </Typography>
              ) : null}
              <Autocomplete
                freeSolo
                {...field}
                forcePopupIcon
                multiple={multiple}
                disableClearable={true}
                options={multiple ? items : items.map((option) => option?.label)}
                value={currentValue}
                getOptionLabel={(option) => (multiple ? option?.label : option)}
                renderInput={(params) => (
                  <TextField
                    error={!!errorMessage}
                    {...params}
                    {...field}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      onChange: (event) => {
                        if (multiple) {
                          if (onChange) onChange(name, event.target.value);
                          return;
                        }
                        field.onChange(event);
                        if (onChange) onChange(name, event.target.value);
                      },
                      onFocus: onInputFocus,
                      ...endAdornment
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (multiple) {
                    field.onChange(newValue);
                  } else {
                    const selectedItem = items.find((item) => item.label === newValue);
                    field.onChange(selectedItem || null);
                  }
                  if (onChange) onChange(name, newValue);
                }}
              />
              {errorMessage && (
                <FormHelperText sx={{color: 'error.main'}} id="outlined-weight-helper-text">
                  <>{errorMessage['0'] || errorMessage?.message || errorMessage}</>
                </FormHelperText>
              )}
            </>
          </FormControl>
        );
      }}
    />
  );
};

export default AutocompleteInput;
