import {BaseRecord} from 'types';
import React, {useEffect, useMemo, useState} from 'react';
import {Box, Table, TableBody, TableContainer} from '@mui/material';
import HeadRows from './head-rows';
import BodyRows from './body-rows';
import styles from './styles.module.scss';
import {useQuery} from 'react-query';
import {useSearchParams} from 'react-router-dom';
import Pagination from '../pagination';
import {usePublicationsContext} from '../../../contexts/PublicatiosContext';

interface TableGreedProps {
  columns: any[];
  requestUrl: string;
  take?: number;
  queryOptions?: Record<string, any>;
  defaultSorting?: {name: string; direction: 'asc' | 'desc'};
  fetchFn: ({cursor}: {cursor: string}, propertyId: string | undefined) => Promise<any>;
  propertyId?: string;
  mockData: Record<string, any>[];
  requestKey: 'admins' | 'publications';
}

const TableGreed = <TResponse extends BaseRecord = any>(props: TableGreedProps) => {
  const {
    columns,
    requestUrl,
    take = 10,
    queryOptions = {},
    defaultSorting,
    fetchFn,
    propertyId = '',
    mockData,
    requestKey = 'admins'
  } = props;
  const {setProperty, refetchTrigger} = usePublicationsContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('cursor') || '';

  const {
    data: tableData = {data: [], cursor: '', property: {}},
    isLoading,
    refetch
  } = useQuery([requestUrl, cursor, refetchTrigger], async () => {
    return await fetchFn({cursor}, propertyId);
  });

  const initialState = {
    page: 0,
    listData: mockData,
    hasMore: true,
    rowsPerPage: take,
    sortOption: {
      dataIndex: defaultSorting?.name || '',
      direction: defaultSorting?.direction || 1
    }
  };

  const [
    {
      page,
      listData,
      sortOption: {dataIndex, direction}
    },
    setState
  ] = useState<any>(initialState);

  const mutate = () => {};
  useEffect(() => {
    return () => {
      if (!searchParams.has('cursor')) {
        return;
      }
      searchParams.delete('cursor');
      setSearchParams(searchParams);
    };
  }, [searchParams]);

  useEffect(() => {
    setProperty(tableData.property);
  }, [tableData.property]);

  const renderData: any = useMemo(() => {
    const hasDataLength = !!tableData?.data || [];
    const skeletonArray = Array.from({length: hasDataLength ? listData.length : 3}, (_, i) => i + 1);
    return isLoading ? skeletonArray : tableData?.data;
  }, [isLoading, tableData.data]);

  return (
    <div className={styles.container}>
      <Box className={styles.wrapper} sx={{borderColor: 'background.label'}}>
        <TableContainer>
          <Table sx={{minWidth: 750, tableLayout: 'auto'}} aria-label="tableTitle" stickyHeader>
            <HeadRows columns={columns} direction={direction} dataIndex={dataIndex} sortFn={setState} />
            <TableBody>
              <BodyRows<TResponse> data={renderData} columns={columns} isLoading={isLoading} mutate={refetch} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        //@ts-ignore
        cursor={tableData?.cursor}
      />
    </div>
  );
};

export default TableGreed;
