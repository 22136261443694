import styles from './styles.module.scss';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useToastContext} from 'contexts/ToastContext';
import {putComment} from 'api/comments';
import InputField from '../../inputField';
import {urlPattern} from 'constants/index';
import {usePublicationsContext} from '../../../../contexts/PublicatiosContext';
import {postManualPublication} from '../../../../api/base';

const AddManualPost: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
}> = ({setOpen, open, propertyId}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const {setRefetchTrigger} = usePublicationsContext();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, watch, register, setValue} = useForm({
    mode: 'onSubmit',
    defaultValues: {
      post_link: '',
      is_original: false,
      contacted_with_owner: false
    }
  });

  const commentValue: string = watch('post_link', '');
  const confirmCheckbox = watch('contacted_with_owner', false);

  const onSubmit = async (data: any) => {
    if (!propertyId || !confirmCheckbox) {
      return;
    }
    setLoading(true);
    const response = await postManualPublication(propertyId, {
      post_link: data.post_link,
      is_original: Boolean(data.is_original),
      contacted_with_owner: Boolean(data.contacted_with_owner)
    });
    setToastData({
      toastBody: response.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setValue('post_link', '');
    setOpenToast(true);
    setLoading(false);
    setRefetchTrigger(Date.now().toString());
    setOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Typography variant={'h5'} sx={{mb: '24px'}}>
            <strong>Додавання поста</strong>
          </Typography>
          <Box className={styles.comment}>
            <InputField
              control={control}
              register={register}
              label={'Телеграм пост *'}
              onChange={() => {}}
              name={'post_link'}
              rules={{
                required: {value: true, message: "Поле обов'язкове"},
                pattern: {
                  value: urlPattern,
                  message: 'Поле має бути дійсною URL-адресою.'
                }
              }}
              size={'small'}
            />
          </Box>
          <Box className={styles.comment}>
            <FormControl className={styles.row}>
              <Controller
                name="is_original"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="radio-buttons-group-label"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value === "true")}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Оригінал" />
                    <FormControlLabel value={false} control={<Radio />} label="Репост" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>
          <Box className={styles.comment}>
            <Controller
              control={control}
              render={({field, fieldState, formState}) => {
                return (
                  <label>
                    <Checkbox {...field} className={styles.checkbox} />
                    <Typography variant={'caption'}>Я зв'язався з власником об'єкту *</Typography>
                  </label>
                );
              }}
              name={`contacted_with_owner`}
            />
          </Box>
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                setValue('post_link', '');
              }}
            >
              <strong>Скасувати</strong>
            </Button>
            <Tooltip disableInteractive title={!Boolean(commentValue) && !loading && 'Заповніть поля'} arrow>
              <span>
                <Button
                  className={styles.button}
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!Boolean(commentValue) || loading || !confirmCheckbox}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <strong>Додати</strong>}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default AddManualPost;
