import React, {useMemo, useState} from 'react';
import {PropertyAdmin} from 'types';
import styles from './styles.module.scss';
import {getSearchAdmins} from 'api/filters';
import {API_ROUTES} from '../../../../../constants';
import {useQuery} from 'react-query';
import {Autocomplete, FormControl, TextField} from '@mui/material';
import {updateAssignee} from 'api/properties';
import {useToastContext} from '../../../../../contexts/ToastContext';
import CircularProgress from '@mui/material/CircularProgress';

const Assignee: React.FC<{assignee: PropertyAdmin | null; propertyId: string; disabled: boolean}> = ({
  assignee,
  propertyId,
  disabled
}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const defaultValues = {
    label: assignee?.id ? `${assignee?.first_name || ''} ${assignee?.last_name || ''}` : '',
    id: assignee?.id || null
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState(defaultValues);
  const [inputValue, setInputValue] = useState<string | null>(null);

  const [name, setName] = useState<null | string>(null);

  const {data} = useQuery<PropertyAdmin[]>(
    [API_ROUTES.ADMINS_SEARCH, inputValue],
    () => getSearchAdmins({only_active: true, name: inputValue || ''}),
    {
      retry: false,
      refetchOnMount: false,
      enabled: inputValue !== null || !loading
    }
  );

  const updateAdmin = async (toId: string | null) => {
    setLoading(true);
    const response = await updateAssignee(propertyId, {
      assignee: {
        from_id: assignee?.id || null,
        to_id: toId || ''
      }
    });
    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setLoading(false);
    setOpenToast(true);
  };

  const onFocus = () => {
    if (name !== null) {
      return;
    }
    setName('');
  };

  const endAdornment = loading ? {endAdornment: <CircularProgress className={styles.circle} size={'20px'} />} : {};

  const items = useMemo(() => {
    return !!data && data?.length
      ? data.map((item: PropertyAdmin) => ({
          label: `${item.first_name} ${item.last_name}`,
          id: item.id
        }))
      : [];
  }, [data]);

  return (
    <FormControl sx={{width: '100%', color: 'background.info'}} className={styles.input}>
      <>
        <Autocomplete
          disabled={disabled}
          value={value}
          onChange={async (event, newValue) => {
            setValue(newValue);
            await updateAdmin(newValue?.id || null);
          }}
          getOptionLabel={(option) => option.label}
          //@ts-ignore
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue || '');
          }}
          onFocus={onFocus}
          disableClearable={true}
          options={items}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,

                ...endAdornment
              }}
              placeholder={'Не заасайнений'}
            />
          )}
        />
      </>
    </FormControl>
  );
};

export default Assignee;
