import React, {FC, useState} from 'react';
import AdminLayout from '../../Layouts/admin-layout';
import {Box} from '@mui/material';
import TableGreed from '../../common/table';
import {EstateCity, IListAdmin, ITableColumns} from '../../../types';
import {formatDateString} from '../../../helpers';
import AdminActions from './admin-actions';
import {API_ROUTES} from '../../../constants';
import {getAdmins} from '../../../api/filters';

const rows: ITableColumns<IListAdmin>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: false
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sortable: false
  },
  {
    title: 'role',
    dataIndex: 'email',
    key: 'role',
    sortable: false,
    render: (item, value, mutate) => {
      return <>{value?.name}</>;
    }
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'active',
    render: (item, value, mutate) => {
      return <>{value ? 'Активний' : 'Не активний'}</>;
    },
    sortable: false
  },
  {
    title: 'Імʼя',
    dataIndex: 'name',
    key: ['first_name', 'last_name'],
    render: (item, value, mutate) => {
      return <>{value}</>;
    },
    sortable: false
  },
  {
    title: 'Міста',
    dataIndex: 'name',
    key: 'cities',
    render: (item, value, mutate) => {
      return <>{value?.map((item: EstateCity) => item?.name)}</>;
    },
    sortable: false
  },
  {
    title: 'Дата створення',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (item, value, mutate) => {
      return <>{formatDateString(value, 'DD.MM.YYYY')}</>;
    },
    sortable: false
  },
  {
    title: 'Дії',
    dataIndex: 'id',
    key: 'id',
    render: (item, value, mutate) => {
      return <AdminActions admin={item} refetch={mutate} />;
    },
    sortable: false
  }
];

const mockData = [
  {
    id: '1',
    name: 'Name',
    event_type: 'Anniversary work',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  },
  {
    id: '2',
    name: 'Name2',
    event_type: 'Anniversary work',
    status: 'Skipped',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  },
  {
    id: '3',
    name: 'Name3',
    event_type: 'Birthday',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  }
];

const AdminsList: FC = () => {
  const [take, setTake] = useState(10);
  return (
    <AdminLayout title={'Адміни'}>
      <Box sx={{height: 400, width: '100%'}}>
        <TableGreed<any>
          columns={rows}
          requestUrl={API_ROUTES.ADMINS}
          defaultSorting={{name: 'created_at', direction: 'asc'}}
          take={take}
          fetchFn={getAdmins}
          mockData={mockData}
          requestKey={'admins'}
        />
      </Box>
    </AdminLayout>
  );
};

export default AdminsList;
