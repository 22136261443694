import {CurrencyCode, FormFields} from '../../../../types';
import {debounce, getNormalisedOptions} from '../../../../helpers';
import {currencyCodeNames, currencySymbols} from '../../../../constants';
import FieldWidget from '../index';
import React, {useMemo, useState} from 'react';
import {InputAdornment, Tooltip, Typography} from '@mui/material';
import classNames from 'classnames';
import styles from '../../inputWithSelect/styles.module.scss';
import {getConvertedCurrency} from '../../../../api/currency';
import {useToastContext} from '../../../../contexts/ToastContext';

const formatCurrency = (amount: string, currencyCode: CurrencyCode) => {
  if (currencyCode) {
    return `${amount} ${currencySymbols[currencyCode]}`;
  }
  return `${currencySymbols[currencyCode]} ${amount}`;
};

const PriceWidget: React.FC<{
  control: any;
  register: any;
  initialAdornmentPrice: string;
  initialAdornmentCode: CurrencyCode;
  watch: any;
  name?: FormFields;
  gridSize?: number;
  getValues?: (name: string) => string;
  rules?: Record<string, any>;
}> = ({
  control,
  register,
  initialAdornmentPrice,
  initialAdornmentCode,
  watch,
  name = FormFields.price,
  gridSize = 6,
  getValues = () => {},
  rules = {}
}) => {
  const [{adornmentCode, adornmentPrice}, setPrice] = useState({
    adornmentCode: initialAdornmentCode,
    adornmentPrice: initialAdornmentPrice
  });

  const {setToastData, setOpenToast} = useToastContext();

  const onPriceChange = debounce(async (name: string, value: string) => {
    const priceValue = getValues(name);
    const currentCurrency = getValues('adornment_price');
    if (!value || !priceValue || !currentCurrency) {
      return;
    }

    try {
      const response = await getConvertedCurrency({
        from_currency: currentCurrency,
        to_currency: currentCurrency === CurrencyCode.UAH ? CurrencyCode.USD : CurrencyCode.UAH,
        amount: priceValue.toString().replace(/[^0-9]/g, '')
      });
      setPrice({adornmentCode: response.currency_code, adornmentPrice: response.amount});
    } catch (error: any) {
      setToastData({
        toastBody: error?.errors?.message,
        toastTitle: 'Помилка',
        toastType: 'error'
      });
      setOpenToast(true);
    }
  }, 1000);

  const formattedPrice = useMemo(() => formatCurrency(adornmentPrice, adornmentCode), [adornmentCode, adornmentPrice]);

  const EndAdornment = useMemo(() => {
    return (
      <InputAdornment position="end" className={classNames(styles.wrapper)}>
        <Tooltip disableInteractive title={formattedPrice} placement="top" arrow>
          <Typography noWrap={true} variant={'body2'} sx={{maxWidth: '75px'}}>
            {formattedPrice}
          </Typography>
        </Tooltip>
      </InputAdornment>
    );
  }, [adornmentCode, adornmentPrice]);

  return (
    <FieldWidget
      control={control}
      register={register}
      filedName={name}
      items={getNormalisedOptions(currencyCodeNames)}
      EndAdornment={EndAdornment}
      onChange={onPriceChange}
      gridSize={gridSize}
      rules={{
        pattern: {
          value: /^[0-9]+$/,
          message: 'Тільки цифри'
        },
        ...rules
      }}
    />
  );
};

export default PriceWidget;
