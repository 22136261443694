import React, {useState} from 'react';
import {Box, Button, CircularProgress, Dialog, IconButton, Paper, Tooltip, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as Trash} from 'svg-icons/trash.svg';
import {removePublication} from '../../../../api/base';
import {useParams} from 'react-router-dom';
import {useToastContext} from '../../../../contexts/ToastContext';
import {usePublicationsContext} from '../../../../contexts/PublicatiosContext';
import {CloseIcon} from 'yet-another-react-lightbox';

const PostActions: React.FC<{publicationId: string}> = ({publicationId}) => {
  const [loading, setLoading] = useState(false);
  const {id: propertyId} = useParams();
  const {setToastData, setOpenToast} = useToastContext();
  const {setRefetchTrigger} = usePublicationsContext();
  const [open, setOpen] = useState(false);

  const onRemoveClick = async () => {
    if (!propertyId && !publicationId) {
      return;
    }
    setLoading(true);
    const response = await removePublication(propertyId!, publicationId);
    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });

    setOpenToast(true);
    setLoading(false);
    setRefetchTrigger(Date.now().toString());
  };
  return (
    <>
      <div className={styles.wrapper}>
        <Tooltip disableInteractive title={'Видалити'} arrow placement={'top'}>
          <Button
            type={'button'}
            component={Button}
            variant={'contained'}
            color={'error'}
            className={styles.icon}
            onClick={() => {
              setOpen(true);
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={'12px'} /> : <Trash />}
          </Button>
        </Tooltip>
      </div>

      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'} sx={{p: 2.4}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '16px'
            }}
          >
            <Typography
              sx={{color: 'text.primary', fontSize: '1.3rem'}}
              className={styles.dialogTitleText}
              variant="h5"
            >
              <strong>Видалення публікації</strong>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography sx={{mb: '32px', color: 'text.customGray', fontSize: '0.9rem'}} className={styles.dialogBodyText}>
            <strong>Ви дійсно хочете видалити публікацію?</strong>
          </Typography>
          <Box className={styles.btns}>
            <Button
              type="button"
              variant="text"
              className={styles.cancelBtn}
              sx={{color: 'text.primary', textTransform: 'none'}}
              onClick={() => {
                setOpen(false);
              }}
            >
              <strong>Скасувати</strong>
            </Button>
            <Button
              variant="contained"
              color="error"
              type="button"
              onClick={onRemoveClick}
              sx={{textTransform: 'none'}}
              className={styles.button}
              disabled={loading}
            >
              {loading ? <CircularProgress size={'24px'} /> : <strong>Видалити</strong>}
            </Button>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default PostActions;
