import {Box, Button, Collapse, Divider, Link, Typography} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useState} from 'react';
import styles from './styles.module.scss';
import ParserFilters from './filters';
import AddIcon from '@mui/icons-material/Add';
import {PROPERTY_URL} from '../../../constants/routes';
import {useLocation} from 'react-router-dom';
import {useFilterContext} from '../../../contexts/FiltersContext';
import SearchBarWithDropdown from '../../common/searchBarWithDropdown';

const countNonEmptyKeys = (obj) => {
  let nonEmptyCount = 0;

  function isNonEmpty(value) {
    if (value === null || value === undefined) return false;
    if (typeof value === 'string') return value.trim() !== '';
    if (typeof value === 'boolean') return true;
    if (typeof value === 'number') return true;
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'object') return Object.keys(value).length > 0;
    return false;
  }

  const recursiveCheck = (obj) => {
    let hasNonEmptyChild = false;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          if (recursiveCheck(value)) {
            hasNonEmptyChild = true;
          }
        } else if (isNonEmpty(value)) {
          hasNonEmptyChild = true;
        }
      }
    }

    if (hasNonEmptyChild) {
      nonEmptyCount++;
    }

    return hasNonEmptyChild;
  };

  recursiveCheck(obj);
  return nonEmptyCount;
};

const ParsersContentLayout = ({children, title, isLoading = false}) => {
  const location = useLocation();
  const isBase = location.pathname.includes(PROPERTY_URL);
  const [open, setOpen] = useState(false);
  const {filters} = useFilterContext();

  const hasFilters = countNonEmptyKeys(filters);

  return (
    <Box sx={{width: '100%', mt: 0, mb: 'auto'}}>
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: '24px',
          position: 'relative'
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Box sx={{display: 'flex', gap: '24px', flexShrink: '0'}}>
          <SearchBarWithDropdown name="search" label="Пошук" />
          {isBase && (
            <Button
              variant="outlined"
              sx={{flexShrink: 0, height: '48px', display: 'flex', gap: '8px', fontWeight: 700}}
              component={Link}
              href={`${PROPERTY_URL}/create`}
            >
              <AddIcon />
              <span>Додати обʼєкт</span>
            </Button>
          )}
          <Button
            variant="contained"
            sx={{flexShrink: 0, height: '48px', display: 'flex', gap: '8px', fontWeight: 700, position: 'relative'}}
            onClick={() => setOpen(!open)}
          >
            {!!hasFilters && <div className={styles.mark}></div>}
            <TuneIcon />
            <span>Фільтри</span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
        <Divider className={styles.divider} />
      </Box>
      <Box>
        <Collapse in={open} timeout="auto">
          <ParserFilters isLoading={isLoading} toggleCollapse={() => setOpen(!open)}/>
        </Collapse>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default ParsersContentLayout;
