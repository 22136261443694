import React, {useState} from 'react';
import {Box, Button, CircularProgress, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as House} from 'svg-icons/house.svg';
import {ReactComponent as AddBaseIcon} from 'svg-icons/base.svg';
import {ReactComponent as EditIcon} from 'svg-icons/edit.svg';
import {ReactComponent as MergeIcon} from 'svg-icons/merge.svg';
import {ReactComponent as TrashIcon} from 'svg-icons/trash.svg';
import {ReactComponent as BookIcon} from 'svg-icons/book.svg';
import {ReactComponent as ComentIcon} from 'svg-icons/message.svg';
import {ReactComponent as ArchiveRestore} from 'svg-icons/archive-restore.svg';
import {ReactComponent as Check} from 'svg-icons/check.svg';
import {ReactComponent as DoNotPublish} from 'svg-icons/do-not-publish.svg';
import {ReactComponent as PostponedPublicationIcon} from 'svg-icons/clipboard.svg';
import {PROPERTY_URL, PARSER_BASE_URL} from 'constants/routes';

import Link from '@mui/material/Link';
import MergePropertyDialog from 'components/common/dialogs/merge';
import {archiveProperty, createFromParsed, deletePropertyFromArchive, putPublicationStatus} from 'api/base';
import {useToastContext} from 'contexts/ToastContext';
import Postponed from 'components/common/dialogs/postponed';
import TrashConfirm from 'components/common/dialogs/trash-confirm';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Comment from '../dialogs/comment';
import {getComment} from '../../../api/comments';
import Archive from '../dialogs/archive';
import Publish from '../dialogs/publish';

const Actions: React.FC<{
  actionList: string[];
  propertyId: string;
  refetch: any;
  formChanged?: boolean;
  size?: 'small' | 'medium';
  redirectAfterMerge?: boolean;
}> = ({actionList, propertyId, refetch, formChanged = false, size = 'small', redirectAfterMerge = false}) => {
  const [open, setOpen] = useState(false);
  const [posponedOpen, setPosponedOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trashOpen, setTrashOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const {setToastData, setOpenToast} = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isParser = location.pathname.includes(PARSER_BASE_URL);
  const {filter: propertyFilter} = useParams();
  const [{propertyComment, propertyCommentLoading}, setPropertyComments] = useState<{
    propertyComment: string;
    propertyCommentLoading: boolean;
  }>({
    propertyComment: '',
    propertyCommentLoading: false
  });

  const setToastAfterResponse = (response: {message: string; success: boolean}) => {
    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setOpenToast(true);
  };

  const actionListConfig: Record<
    string,
    {Icon: React.FC<any>; href: null | string; onClick: () => void; tooltip: string}
  > = {
    edit: {
      Icon: EditIcon,
      href: isParser
        ? `${PARSER_BASE_URL}/${propertyFilter}/${propertyId}`
        : `${PROPERTY_URL}/${propertyFilter}/${propertyId}`,
      onClick: () => {},
      tooltip: 'Редагувати'
    },
    putOff: {
      Icon: AddBaseIcon,
      href: null,
      onClick: async () => {
        try {
          setLoading(true);
          const response = await createFromParsed({parsed_property_id: propertyId});
          setToastAfterResponse(response);
          setLoading(false);
          if (redirectAfterMerge) {
            navigate(`/${PROPERTY_URL}/unpublished`);
          }
          refetch();
        } catch (error: any) {
          if (error.handleError) {
            setToastData({
              toastBody: error.errors.message,
              toastTitle: 'Помилка',
              toastType: 'error'
            });
            setLoading(false);
            setOpenToast(true);
          }
        }
        refetch();
      },
      tooltip: 'В базу'
    },
    merge: {
      Icon: MergeIcon,
      href: null,
      onClick: () => {
        setOpen(true);
      },
      tooltip: "Об'єднати об'єкти"
    },
    postponed: {
      Icon: House,
      href: null,
      onClick: () => {
        setPosponedOpen(true);
      },
      tooltip: 'Відкласти'
    },
    postponedPublication: {
      Icon: PostponedPublicationIcon,
      href: null,
      onClick: () => {},
      tooltip: 'Відкладена публікація '
    },
    trash: {
      Icon: TrashIcon,
      href: null,
      onClick: () => {
        setTrashOpen(true);
      },
      tooltip: isParser ? 'В смітник' : 'Видалити'
    },
    archive: {
      Icon: ArchiveRestore,
      href: null,
      onClick: () => {
        setArchiveOpen(true);
      },
      tooltip: 'В архів'
    },
    revivedFromArchive: {
      Icon: BookIcon,
      href: null,
      onClick: async () => {
        setLoading(true);
        const response = await deletePropertyFromArchive(propertyId);
        setToastAfterResponse(response);
        setLoading(false);
        if (redirectAfterMerge) {
          navigate(`${PROPERTY_URL}/unpublished/${propertyId}`, {replace: true});
        }
        refetch();
      },
      tooltip: 'З архіву'
    },
    published: {
      Icon: Check,
      href: null,
      onClick: () => {
        setPublishOpen(true);
      },
      tooltip: 'Публікація'
    },
    doNotPublish: {
      Icon: DoNotPublish,
      href: null,
      onClick: async () => {
        const response = await putPublicationStatus(propertyId, {publication_status: 'do_not_publish'});
        setToastAfterResponse(response);
        setLoading(false);
        refetch();
      },
      tooltip: 'Не публікувати'
    },
    comment: {
      Icon: ComentIcon,
      href: null,
      onClick: async () => {
        setLoading(true);
        try {
          const response = await getComment(propertyId);
          setPropertyComments((state) => ({
            ...state,
            propertyComment: response.message
          }));
          setLoading(false);
          setCommentOpen(true);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      },
      tooltip: 'Коментар'
    }
  };

  const sortedActionList = actionList.sort((a, b) => {
    if (a === 'edit') return -1;
    if (b === 'edit') return 1;
    return 0;
  });

  return (
    <>
      {sortedActionList.map((item, index) => {
        const Icon: React.FC<any> = actionListConfig[item].Icon;
        const isLink = !!actionListConfig[item].href;
        const restProps = isLink
          ? {href: actionListConfig[item].href}
          : {
              onClick: actionListConfig[item].onClick
            };
        return (
          <Tooltip
            disableInteractive
            title={actionListConfig[item].tooltip}
            placement="top"
            key={`action_${index}`}
            arrow
          >
            <span className={styles[size]}>
              {
                //@ts-ignore
                <Button
                  component={isLink ? Link : Button}
                  aria-label={item}
                  variant={'contained'}
                  color={item === 'trash' ? 'error' : 'primary'}
                  className={styles.icon}
                  disabled={formChanged || loading}
                  {...restProps}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <Icon />}
                </Button>
              }
            </span>
          </Tooltip>
        );
      })}
      <MergePropertyDialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        parsedPropertyId={propertyId}
        redirectAfterMerge={redirectAfterMerge}
      />
      {publishOpen && (
        <Publish
          open={publishOpen}
          setOpen={setPublishOpen}
          propertyId={propertyId}
          refetch={refetch}
          isRepost={false}
        />
      )}

      <Postponed open={posponedOpen} setOpen={setPosponedOpen} propertyId={propertyId} refetch={refetch} />
      <TrashConfirm setOpen={setTrashOpen} open={trashOpen} propertyId={propertyId} refetch={refetch} />
      <Comment
        setOpen={setCommentOpen}
        open={commentOpen}
        propertyId={propertyId}
        refetch={refetch}
        comment={propertyComment}
      />
      <Archive
        setOpen={setArchiveOpen}
        open={archiveOpen}
        propertyId={propertyId}
        refetch={refetch}
        comment={propertyComment}
      />
    </>
  );
};

export default Actions;
