import api from './server';

import {PropertyListItemType, PropertySingleItemType, ResponseWithPagination} from '../types';
import {API_ROUTES} from '../constants';

export const mergeProperty = async ({
  parsedPropertyId,
  propertyId
}: {
  parsedPropertyId: string;
  propertyId: string;
}): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES}/${propertyId}/merge`, {
      parsed_property_id: parsedPropertyId
    });
    return {
      success: true,
      ...response.data
    };
  } catch (err: any) {
    if (err.handleError) {
      return {
        success: false,
        message: err.errors.message
      };
    }
    return {
      success: false,
      message: err.response.data.message
    };
  }
};

export const getParsedProperties = async (params: any): Promise<ResponseWithPagination<PropertyListItemType[]>> => {
  try {
    const response = await api.get(API_ROUTES.PARSED_PROPERTIES, {params});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putParsedProperties = async (propertyId: string, data: any) => {
  try {
    const response = await api.put(`${API_ROUTES.PARSED_PROPERTIES}/${propertyId}/`, data);
    return {
      success: true,
      message: response.data.message
    };
  } catch (error: any) {
    if (error.handleError) {
      throw error;
    }
    console.log(error);
  }
};

export const getParsedProperty = async ({id}: {id: string}): Promise<PropertySingleItemType> => {
  const response = await api.get(`/parsed-properties/${id}`);
  return response.data.data;
};

export const updateAssignee = async (
  propertyId: string,
  assignee: {
    assignee: {
      from_id: string | null;
      to_id: string;
    };
  }
): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.put(`${API_ROUTES.PARSED_PROPERTIES}/${propertyId}/assignee`, assignee);
    return {
      success: true,
      ...response?.data
    };
  } catch (err: any) {
    if (err.handleError) {
      return {
        success: false,
        message: err.errors.message
      };
    }
    return {
      success: false,
      message: err.response.data.message
    };
  }
};
