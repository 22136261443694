import styles from './styles.module.scss';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useToastContext} from 'contexts/ToastContext';
import TextArea from 'components/common/text-area';
import {putComment} from 'api/comments';
import {CloseIcon} from 'yet-another-react-lightbox';

const Comment: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
  comment: string;
}> = ({setOpen, open, propertyId, refetch, comment}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, watch, register, setValue} = useForm({
    mode: 'onSubmit'
  });

  const commentValue: string = watch('comment', '');

  const onSubmit = async (data: Record<string, string>) => {
    if (!propertyId) {
      return;
    }
    setLoading(true);
    const response = await putComment(propertyId, {comment: data.comment});
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setValue('comment', '');
    setOpenToast(true);
    setOpen(false);
    setLoading(false);
    refetch();
  };
  useEffect(() => {
    setValue('comment', comment || '');
  }, [comment]);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '24px'
            }}
          >
            <Typography variant={'h5'}>
              <strong>Додавання коментаря</strong>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={styles.comment}>
            <TextArea
              disabled={loading}
              control={control}
              register={register}
              label={'Додайте коментар'}
              onChange={() => {}}
              name={'comment'}
              rules={{
                required: {value: true, message: "Поле обов'язкове"},
                minLength: {
                  value: 3,
                  message: 'Мінімум 3 символи.'
                }
              }}
            />
          </Box>
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                setValue('comment', '');
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Tooltip disableInteractive title={!Boolean(commentValue) && !loading && 'Заповніть поле'} arrow>
              <span>
                <Button
                  className={styles.button}
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!Boolean(commentValue) || loading}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <strong>Додати</strong>}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default Comment;
