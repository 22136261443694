import React, {createContext, Dispatch, SetStateAction, ForwardedRef} from 'react';

import {CssBaseline, useMediaQuery} from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {Link as RouterLink, RouterProps} from 'react-router-dom';

import {useLocalStorage} from '../hooks/useLocalStorage';

type ThemeType = 'dark' | 'light';

interface ThemeContextType {
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
}

interface LinkBehaviorProps extends Omit<RouterProps, 'to'> {
  href: string;
}

const LinkBehavior = React.forwardRef((props: LinkBehaviorProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const {href, ...other} = props;

  return <RouterLink ref={ref} to={href} {...other} />;
});

const DEFAULT_THEME = 'dark';
export const ThemeContext = createContext<ThemeContextType>({
  theme: DEFAULT_THEME,
  setTheme: () => {}
});

export const ThemeProvider = ({children}: {children: React.ReactNode}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const {value, getItem, setItem} = useLocalStorage('theme');

  const getOrDefault = (theme: ThemeType) => {
    if (!theme) {
      theme = prefersDarkMode ? 'dark' : 'light';
    }
    return theme;
  };

  const handleToggleTheme = () => {
    const updatedValue = getOrDefault(getItem() as ThemeType);
    setItem(updatedValue !== 'dark' ? 'dark' : 'light');
  };

  const themeContextValue = {
    theme: getOrDefault(value as ThemeType),
    setTheme: handleToggleTheme
  };

  const customizations =
    themeContextValue.theme === 'dark'
      ? {
          palette: {
            mode: themeContextValue.theme,
            background: {
              main: '#4760BB',
              default: '#121212',
              paper: '#292929',
              secondary: '#202020',
              info: '#9BC9F8',
              label: '#2F2F2F',
              label2: '#1A1A1A',
              blue: '#9BC9F8',
              success: '#39C170',
              error: '#FF8282',
              warning: '#E28035',
              chip: {
                pending: '#B89E69',
                done: '#47A57E',
                failed: '#FF8282',
                archived: '#121212',
                postponed: '#5B5B5B',
                original: '#4760BB',
                unoriginal: '#9880A0'
              }
            },
            text: {
              secondary: '#C0C0C0',
              primary: '#FFFFFF',
              dark: '#121212',
              customGray: '#8D8D8D',
              success: '#39C170',
              error: '#FF8282'
            },
            dark: {
              main: '#121212',
              secondary: '#525252',
              gray: '#2C2C2C'
            },
            error: {
              main: '#FF8282'
            }
          }

          //dark theme
        }
      : {
          //light theme
          palette: {
            mode: themeContextValue.theme,
            background: {
              main: '#4760BB',
              primary: '#4760BB',
              default: '#fff',
              paper: '#e8e8e8',
              secondary: '#CBCBCBFF',
              info: '#9BC9F8',
              label: '#CBCBCBFF',
              label2: '#CBCBCBFF',
              blue: '#9BC9F8',
              success: '#39C170',
              error: '#FF8282',
              warning: '#E28035',
              chip: {
                pending: '#FFE3AB',
                done: '#52EDAD',
                failed: '#FBC7C7',
                archived: '#EEEEEE',
                postponed: '#E7E7E7',
                original: '#C6D2FF',
                unoriginal: '#E5D4EB'
              }
            },
            text: {
              primary: '#121212',
              secondary: '#425053',
              disabled: '#ebeae5',
              dark: '#121212',
              customGray: '#7b7b7b',
              success: '#39C170',
              error: '#FF8282'
            },
            dark: {
              main: '#425053',
              secondary: '#525252',
              gray: '#2C2C2C'
            }
          }
        };

  const themeObj = createTheme({
    ...customizations,
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior
        }
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior
        },
        styleOverrides: {
          root: {
            borderRadius: '8px!important;',
            textTransform: 'none'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '8px!important;'
          },
          sizeSmall: {
            minHeight: '24px!important',
            fontSize: '0.625rem'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          //@ts-ignore
          sizeSmall: {
            minHeight: '24px',
            lineHeight: '1.1',
            fontSize: '0.625rem'
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: customizations.palette.text.secondary,
            ':hover': {
              backgroundColor: customizations.palette.background.info,
              color: customizations.palette.text.dark
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          h4: {
            fontSize: '2rem'
          },
          h5: {
            fontSize: '1.25rem',
            color: customizations.palette.text.primary
          },
          h6: {
            fontSize: '1rem',
            color: customizations.palette.text.primary
          },
          caption: {
            color: customizations.palette.text.customGray
          },
          //@ts-ignore
          captionSmall: {
            fontSize: '0.75rem',
            color: customizations.palette.text.primary
          },
          body2: {
            color: customizations.palette.text.customGray
          },
          subtitle1: {
            fontSize: '0.875rem',
            color: customizations.palette.text.primary,
            lineHeight: 1.2
          },
          subtitle2: {
            fontSize: '0.875rem',
            color: customizations.palette.text.customGray,
            lineHeight: 1.2
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          //@ts-ignore
          borderColor: 'red'
        }
      }
    }
  });

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <MuiThemeProvider theme={themeObj}>
        <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}} />
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
