import {EstateCity, IStreet, PropertyAdmin, RawItem, ResponseWithPagination} from '../types';
import api from './server';
import {API_ROUTES} from '../constants';

export async function getDistrict(params: any = {name: ''}): Promise<RawItem[]> {
  const response = await api.get(decodeURIComponent(`${API_ROUTES.DISTRICTS}?name=${params.name}`));
  return response.data.data;
}

export async function getStreetStreets(params: any = {name: ''}): Promise<IStreet[]> {
  const response = await api.get(`${API_ROUTES.STREETS}?street_name=${params.name}`);
  return response.data.data;
}

export async function getCities(): Promise<EstateCity[]> {
  const response = await api.get(API_ROUTES.CITIES);
  return response.data.data;
}

export async function getResidentialComplex(params: any = {name: ''}): Promise<RawItem[]> {
  const response = await api.get(`${API_ROUTES.RESIDENTIAL_COMPLEXES}?name=${params.name}`);
  return response.data.data;
}

export async function getBuildingTypes(params: any = {name: ''}): Promise<RawItem[]> {
  const response = await api.get(`${API_ROUTES.BUILDING_TYPES}?name=${params.name}`);
  return response.data.data;
}

export async function getSearchAdmins(params: {only_active: boolean; name: string}): Promise<PropertyAdmin[]> {
  const response = await api.get(API_ROUTES.ADMINS_SEARCH, {params});
  return response.data.data;
}

export async function getAdmins(params: {cursor: string}): Promise<ResponseWithPagination<PropertyAdmin[]>> {
  const response = await api.get(API_ROUTES.ADMINS, {params});
  return response.data;
}
