import styles from './styles.module.scss';
import {Box, Button, CircularProgress, Dialog, IconButton, Paper, Typography} from '@mui/material';
import React, {useState} from 'react';
import {postLabels} from 'api/labels';
import {PropertyLabelsType} from 'types';
import {useToastContext} from 'contexts/ToastContext';
import {deleteProperty} from '../../../../api/base';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PROPERTY_URL} from '../../../../constants/routes';
import {CloseIcon} from 'yet-another-react-lightbox';

const TrashConfirm: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
}> = ({setOpen, open, propertyId, refetch}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isBase = location.pathname.includes(PROPERTY_URL);
  const navigate = useNavigate();
  const {filter} = useParams();

  const onSubmit = async () => {
    if (!propertyId) {
      return;
    }
    setLoading(true);
    const response = isBase
      ? await deleteProperty(propertyId)
      : await postLabels(propertyId, {
          alias: PropertyLabelsType.TRASH,
          comment: ''
        });
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });

    setOpenToast(true);
    setOpen(false);
    setLoading(false);
    if (isBase) {
      navigate(`${PROPERTY_URL}/${filter}`, {replace: true});
    }
    refetch();
  };

  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      className={styles.dialogWrapper}
    >
      <Paper component={'form'} sx={{p: 2.4}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '16px'
          }}
        >
          <Typography sx={{color: 'text.primary', fontSize: '1.3rem'}} className={styles.dialogTitleText} variant="h5">
            <strong>{isBase ? 'Видалення публікації' : "Увага об'єкт буде відправлено в смітник"}</strong>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{mb: '32px', color: 'text.customGray', fontSize: '0.9rem'}} className={styles.dialogBodyText}>
          <strong>
            {isBase ? 'Ви дійсно хочете видалити публікацію? ' : "Увага об'єкт буде відправлено в смітник"}
          </strong>
        </Typography>
        <Box className={styles.btns}>
          <Button
            type="button"
            variant="text"
            className={styles.cancelBtn}
            sx={{color: 'text.primary', textTransform: 'none'}}
            onClick={() => {
              setOpen(false);
            }}
          >
            <strong>Скасувати</strong>
          </Button>
          <Button
            variant="contained"
            color="error"
            type="button"
            onClick={onSubmit}
            sx={{textTransform: 'none'}}
            className={styles.button}
            disabled={loading}
          >
            {loading ? <CircularProgress size={'24px'} /> : <strong>Видалити</strong>}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default TrashConfirm;
