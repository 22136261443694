import React from 'react';
import {filterFields} from '../../../../mock';
import {Box, Button, Divider, Grid, IconButton, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import InputField, {InputSizes} from '../../inputField';
import {getNormalisedOptions} from '../../../../helpers';
import {propertyContactRole, urlPattern} from '../../../../constants';
import InputMask from '../../inputMask';
import ControlPointSharpIcon from '@mui/icons-material/ControlPointSharp';
import {RawEstateContacts} from '../../../../types';
import AddIcon from '@mui/icons-material/Add';
import {Controller, useFieldArray} from 'react-hook-form';
import {ReactComponent as UserCheck} from 'svg-icons/user-check.svg';
import classNames from 'classnames';

const ContactInfo: React.FC<{
  initialData: RawEstateContacts[];
  control: any;
  register: any;
  setValue: (name: string, value: any) => void;
  getValues: any;
  watch: any;
}> = ({initialData, control, register, setValue, watch, getValues}) => {
  const {fields, append, remove, update} = useFieldArray({
    control,
    name: 'contacts'
  });
  const defaultPhoneNumberState = { id: null, initialId: null, phone_number: '', linked: false };

  const addNumberField = (index: number) => {
    const extraFieldValue = getValues(`contacts.${index}.extraField`);

    if (!extraFieldValue) {
      console.warn("Field 'extraField' is empty");
      return;
    }

    const currentItem = getValues(`contacts.${index}`);
    const additionalPhoneNumber = {...defaultPhoneNumberState, phone_number: extraFieldValue};
    const updatedPhoneNumbers = [...currentItem.phone_numbers, additionalPhoneNumber];

    update(index, {
      ...currentItem,
      phone_numbers: updatedPhoneNumbers
    });

    setValue(`contacts.${index}.extraField`, '');
  };

  return (
    <>
      {fields.length > 0 &&
        fields.map((contact: any, index) => {
          const nameConfig = filterFields['name'];
          const roleConfig = filterFields['role'];
          const NameWidget = nameConfig.Widget;
          const RoleWidget = roleConfig.Widget;
          const FakeTag = React.Fragment;
          const newPhoneNumber = watch(`new_phone_numbers_${contact.id}`, '');

          if (!contact.phone_numbers?.length) {
            contact.phone_numbers = [defaultPhoneNumberState];
          }

          return (
            <FakeTag key={`contact_name_${index}`}>
              <Grid item xs={6} className={styles.row}>
                <Box className={styles.hidden}>
                  <NameWidget
                    control={control}
                    register={register}
                    label={nameConfig.label}
                    name={`contacts.${index}.initialId`}
                    placeholder={nameConfig.placeholder}
                    onChange={() => {}}
                    size={nameConfig.size as InputSizes}
                  />
                </Box>
                <NameWidget
                  control={control}
                  register={register}
                  label={nameConfig.label}
                  name={`contacts.${index}.name`}
                  placeholder={nameConfig.placeholder}
                  onChange={() => {}}
                  size={nameConfig.size as InputSizes}
                  rules={{
                    required: {value: true, message: "Поле обов'язкове"}
                  }}
                />
              </Grid>
              <Grid item xs={6} className={styles.row}>
                <RoleWidget
                  control={control}
                  register={register}
                  label={roleConfig.label}
                  name={`contacts.${index}.role`}
                  placeholder={roleConfig.placeholder}
                  onChange={() => {}}
                  size={roleConfig.size as InputSizes}
                  items={getNormalisedOptions(propertyContactRole)}
                />
              </Grid>
              {contact?.phone_numbers?.length > 0 &&
                contact.phone_numbers.map((phoneNumber: any, numIndex: number) => {
                  const config = filterFields['phone_numbers'];
                  const Widget = config.Widget;
                  const rules = numIndex === 0 ? {required: {value: true, message: "Поле обов'язкове"}} : {};
                  return (
                    <Grid item xs={6} className={classNames(styles.row, styles.relative)} key={`name_${numIndex}`}>
                      <Widget
                        control={control}
                        label={config.label}
                        name={`contacts.${index}.phone_numbers.${numIndex}.phone_number`}
                        placeholder={config.placeholder}
                        onChange={() => {}}
                        size={config.size as InputSizes}
                        sx={{paddingRight: '32px'}}
                        rules={rules}
                      />
                      <Box className={styles.linked}>
                        <Tooltip disableInteractive title={'Номер з оголошення'} placement="top" arrow>
                          <>
                            <Controller
                              control={control}
                              render={({field, fieldState, formState}) => {
                                return (
                                  <input
                                    type="checkbox"
                                    {...field}
                                    disabled={true}
                                    checked={field.value}
                                    readOnly={true}
                                  />
                                );
                              }}
                              name={`contacts.${index}.phone_numbers.${numIndex}.linked`}
                            />
                            <IconButton component={'div'} className={styles.checked} color={'primary'}>
                              <UserCheck />
                            </IconButton>
                          </>
                        </Tooltip>
                      </Box>
                    </Grid>
                  );
                })}
              <Grid item xs={6} className={styles.row} sx={{position: 'relative'}}>
                <Box sx={{width: 1}}>
                  <InputMask
                    control={control}
                    label={`Додатковий номер телефону`}
                    name={`contacts.${index}.extraField`}
                    placeholder={'Додати новий номер'}
                    onChange={() => {}}
                    size={'medium'}
                  />
                </Box>
                <Tooltip disableInteractive title={!newPhoneNumber ? 'Заповніть поле Номер' : ''} placement="top" arrow>
                  <Box className={styles.buttonWrapper}>
                    <IconButton
                      className={styles.button}
                      sx={{color: 'background.info', bgcolor: 'background.default'}}
                      disabled={false}
                      onClick={() => {
                        addNumberField(index);
                      }}
                      type={'button'}
                    >
                      <ControlPointSharpIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} className={styles.row} sx={{w: 1}}>
                <Box>
                  <InputField
                    control={control}
                    name={`contacts.${index}.comment`}
                    register={register}
                    label={contact.comment ? 'Коментар до обʼєкту' : 'Додати Коментар'}
                    onChange={() => {}}
                    size={'medium'}
                    sx={{bgcolor: 'background.label', borderRadius: '8px'}}
                  />
                </Box>
              </Grid>
              {index !== fields.length - 1 ? (
                <Divider sx={{width: 'calc(100% + 16px)', height: '1px', pt: '12px'}} />
              ) : (
                <Box sx={{width: '100%', height: '10px'}} />
              )}
            </FakeTag>
          );
        })}
      <Button
        variant={'outlined'}
        type={'button'}
        className={styles.addContact}
        sx={{bgcolor: 'background.default', ':hover': {bgcolor: 'background.default'}}}
        onClick={() => {
          append({id: Date, initialId: null, name: '', comment: '', phone_numbers: [], role: undefined});
        }}
      >
        <AddIcon />
        Додати контакт
      </Button>
    </>
  );
};

export default ContactInfo;
