import {FormControl, FormHelperText, Input, OutlinedInput, Typography} from '@mui/material';
import React from 'react';
import {IMaskInput} from 'react-imask';
import styles from '../inputField/styles.module.scss';
import {Controller} from 'react-hook-form';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, value, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-00-00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
      value={value || ''}
    />
  );
});

const InputMask = ({
  control,
  onChange,
  label,
  name,
  adornmentStart = '',
  size = '',
  placeholder = '',
  sx = {},
  rules = {}
}) => {
  return (
    <FormControl variant="standard" className={styles[size]}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        rules={rules}
        render={({field, fieldState, formState}) => {
          const getErrorMessage = (fieldName, errors) => {
            return fieldName.split('.').reduce((acc, key) => acc?.[key] || null, errors);
          };

          const errorMessage = getErrorMessage(name, formState.errors);
          return (
            <>
              <OutlinedInput
                {...field}
                value={field.value}
                onChange={(event) => {
                  field.onChange(event);
                }}
                name={name}
                inputComponent={TextMaskCustom}
                placeholder={'(000) 000-00-00'}
                error={!!errorMessage}
              />
              {errorMessage && (
                <FormHelperText sx={{color: 'error.main'}}>
                  <>{errorMessage['0'] || errorMessage?.message || errorMessage}</>
                </FormHelperText>
              )}
            </>
          );
        }}
        name={name}
      />
    </FormControl>
  );
};

export default InputMask;
