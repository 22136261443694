import React, {useEffect, useMemo, useState} from 'react';
import {Box, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Typography} from '@mui/material';
import Header from '../header';
import Status from '../status';
import {
  Children,
  ContactRole,
  CurrencyCode,
  EastType,
  FormFields,
  OfferType,
  Pets,
  PropertyLabelsType,
  PropertySingleItemType,
  PropertyStatus,
  PublicationStatus,
  StreetType
} from 'types';
import {Controller, useForm} from 'react-hook-form';
import {getNormalisedOptions} from 'helpers';
import {
  API_ROUTES,
  childrenAllowedStatus,
  eastTypeNames,
  floorList,
  offerTypeNames,
  petsAllowedStatus,
  propertyRooms
} from 'constants/index';
import useCity from 'hooks/useCity';
import ContactInfo from '../../widgets/contact-info';
import FieldWidget from '../../widgets';
import DraggableImageGallery from '../../draggableImageGallery';
import useFormWatcher from '../../../../hooks/useFormWatcher';
import {useFormWatcherContext} from '../../../../contexts/FormWatcherProvider';
import SourcesInfo from '../../widgets/sources-info';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import {getPropertyMedia} from '../../../../api/media';
import {useParams} from 'react-router-dom';
import PriceWidget from '../../widgets/price';
import dayjs from 'dayjs';
import Street from '../../widgets/street';
import {useUser} from '../../../../hooks/useUser';
import District from '../../widgets/district';
import MainInfo from '../main-info';
import {getBuildingTypes, getResidentialComplex} from '../../../../api/filters';
import AutocompleteWithQuery from '../../autocomplete-with-query';

const rawDefaultContactData = [
  {
    id: null,
    initialId: null,
    comment: '',
    phone_numbers: [
      {
        id: null,
        phone_number: '',
        linked: false
      }
    ],
    role: ContactRole.UNKNOWN,
    name: ''
  }
];

const PropertyForm: React.FC<{
  data: PropertySingleItemType | null;
  isParser: boolean;
  onSubmit: any;
  type: 'create' | 'edit';
}> = React.forwardRef(({data, isParser, onSubmit, type = 'edit'}, ref) => {
  const {propertyImages, setPropertyImages} = useFormWatcherContext();
  const {cities} = useCity();
  const {id: propertyId} = useParams();
  const [resyncStart, setResincStart] = useState<boolean>(false);
  const {user} = useUser();
  const posponedLabel = data
    ? data?.labels?.find((item) => item.label.alias === PropertyLabelsType.POSTPONED)?.comment
    : '';

  const getDefaultValues = () => {
    const sourcesFieldsDefaultValues = data?.sources?.reduce((acc, source) => {
      return {
        ...acc,
        [`source_type_${source.id}`]: source.source.type,
        [`source_id_${source.id}`]: source.source_id,
        [`source_link_${source.id}`]: source?.link ? source?.link : '',
        ...(source.primary ? {[`source_primary`]: source.id} : {})
      };
    }, {});

    const featuresFieldsDefaultValues = {
      ...{
        'features.pets': Pets.UNKNOWN,
        'features.children': Children.UNKNOWN
      },
      ...data?.features?.reduce(
        (acc, feature) => {
          (acc as Record<string, any>)[`features.${feature.feature.alias}`] = feature.value;
          return acc;
        },
        {} as Record<string, any>
      )
    };

    const defaultContacts = data?.contacts.map((contact) => ({
      id: contact.id,
      initialId: contact.id,
      comment: contact.comment,
      phone_numbers: contact.phone_numbers,
      role: contact.role || ContactRole.UNKNOWN,
      name: contact.name || ''
    }));

    return {
      status: type === 'create' ? PropertyStatus.ACTIVE : data?.status,
      property_id: data?.id || '',
      estate_type: type === 'create' ? EastType.APARTMENT : data?.estate_type || '',
      price: data?.price?.original?.amount || '',
      offer_type: type === 'create' ? OfferType.RENT : data?.offer_type || '',
      contacts: defaultContacts?.length ? defaultContacts : rawDefaultContactData,
      adornment_price: data?.price?.original?.currency_code || CurrencyCode.UAH,
      assignee: data?.assignee?.id
        ? {label: `${data?.assignee?.first_name} ${data?.assignee?.last_name}`, value: data?.assignee?.id}
        : '',
      ...(data?.comment && {comment: data?.comment}),
      ...sourcesFieldsDefaultValues,
      ...(data?.address?.street_name && {
        'address.street_name': data.address.street_name
      }),
      'adornment_address.street_name': data?.address?.street_type || StreetType.STREET,
      'address.building': data?.address?.building || undefined,
      'address.section': data?.address?.section || undefined,
      'address.entrance': data?.address?.entrance || undefined,
      'address.apartment': data?.address?.apartment || undefined,
      'address.geo_link': data?.address?.geo_link || undefined,
      'address.is_center': data?.address?.is_center || false,
      ...(data?.address?.city?.code && {
        'address.city_code': {label: data.address.city.name, value: data.address.city.code}
      }),
      ...(data?.address?.district?.name && {
        'address.district': {label: data.address.district.name, value: data.address.district.id}
      }),
      'address.newly_built': !isNaN(Number(data?.address?.newly_built))
        ? Number(data?.address?.newly_built).toString()
        : '1',
      ...(data?.address?.residential_complex?.name && {
        'address.residential_complex': {
          label: data?.address?.residential_complex.name,
          value: data.address.residential_complex.id
        }
      }),
      'address.building_type': data?.address?.building_type?.name || undefined,
      ...featuresFieldsDefaultValues,
      ...(posponedLabel && {postponed_comment: posponedLabel}),
      ...(data?.last_time_contacted_at &&
        !isParser &&
        type === 'edit' && {
          last_time_contacted_at: dayjs(data.last_time_contacted_at)
        }),
      ...(type === 'create' && {
        last_time_contacted_at: dayjs(Date.now())
      }),
      ...(data?.publication_status &&
        !isParser &&
        type === 'edit' && {
          publication_status: data?.publication_status
        }),
      ...(type === 'create' && {
        publication_status: PublicationStatus.UNPUBLISHED
      }),
      ...(data?.author?.id &&
        !isParser &&
        type === 'edit' && {
          author_id: {
            value: data?.author?.id,
            label: `${data?.author?.first_name} ${data?.author?.last_name}`
          }
        }),
      ...(type === 'create' && {
        author_id: {value: user?.id, label: `${user?.first_name} ${user?.last_name}`}
      }),
      ...(data?.callback_at &&
        !isNaN(new Date(data.callback_at).getTime()) &&
        !isParser && {
          callback_at: dayjs(data.callback_at)
        }),
      ...(data?.closed_at &&
        !isNaN(new Date(data.closed_at).getTime()) &&
        !isParser && {
          closed_at: dayjs(data.closed_at)
        }),
      media: []
    };
  };

  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState,
    formState: {errors},
    setError,
    unregister,
    getValues,
    clearErrors
  } = useForm<any>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getDefaultValues()
  });

  React.useImperativeHandle(ref, () => ({
    submit: handleSubmit(onSubmit),
    defaultValues: formState.defaultValues,
    setFormErrors: setError,
    formState: formState
  }));

  const isChanged = useFormWatcher(control, formState.defaultValues);
  const {setFormContextChanged, hasPendingUploads, setHasPendingUploads} = useFormWatcherContext();
  const displayBuildingTypesSelect = watch('address.newly_built', formState.defaultValues?.['address.newly_built']);

  const onResync = async (refetchCount: number = 600) => {
    if (!propertyId) {
      return;
    }
    setResincStart(true);
    try {
      const response = await getPropertyMedia(
        propertyId,
        isParser ? API_ROUTES.PARSED_PROPERTIES : API_ROUTES.PROPERTIES
      );

      if (!response.has_pending_uploads) {
        setHasPendingUploads(response?.has_pending_uploads);
        setResincStart(false);
        setPropertyImages(response.media);
        return;
      }
      if (refetchCount <= 0) {
        setHasPendingUploads(false);
        setResincStart(false);
        return;
      }
      setTimeout(() => {
        onResync(refetchCount - 1);
      }, 1000);
    } catch (error) {
      setResincStart(false);
      setHasPendingUploads(false);
    }
  };

  useEffect(() => {
    setFormContextChanged(isChanged);
  }, [isChanged]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setHasPendingUploads(data.has_pending_uploads);
  }, [data?.has_pending_uploads, setFormContextChanged]);

  useEffect(() => {
    if (!hasPendingUploads || !data || !propertyId) {
      return;
    }
    if (resyncStart) {
      return;
    }
    onResync().then(() => {});
  }, [data, resyncStart, hasPendingUploads, onResync, propertyId, setHasPendingUploads, setPropertyImages]);

  const formatedImagesArray = useMemo(() => {
    return propertyImages?.length ? propertyImages?.map((image, index) => ({order: index, ...image})) : [];
  }, [propertyImages]);

  return (
    <Grid component={'form'} container spacing={3}>
      <Grid item xs={8} sx={{pb: '32px'}}>
        {data && (
          <Grid sx={{pb: '24px'}} container spacing={2}>
            <Grid item xs={12}>
              <Header
                sources={data.sources}
                estateType={data.estate_type}
                title={data.title}
                createdAt={data.created_at}
              />
              <Divider sx={{pt: '12px'}} />
            </Grid>
          </Grid>
        )}
        <Grid sx={{pb: '24px'}} container spacing={2}>
          <Grid item xs={12}>
            <Status control={control} register={register} isParser={isParser} />
            <Divider sx={{pt: '12px'}} />
          </Grid>
          {!isParser && (
            <Grid item xs={12}>
              <FieldWidget
                control={control}
                register={register}
                filedName={FormFields.comment}
                gridSize={12}
                rules={{
                  minLength: {
                    value: 3,
                    message: 'Мінімум 3 символи.'
                  }
                }}
              />
            </Grid>
          )}
          {posponedLabel && (
            <Grid item xs={12}>
              <FieldWidget
                control={control}
                register={register}
                filedName={FormFields.postponed_comment}
                gridSize={12}
              />
            </Grid>
          )}
          {!isParser && <MainInfo control={control} register={register} disabled={type !== 'create'} />}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{fontWeight: 700}}>
              Інформація про пропозицію
            </Typography>
          </Grid>
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.property_id}
            items={[]}
            disabled={true}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.estate_type}
            items={getNormalisedOptions(eastTypeNames)}
          />
          <PriceWidget
            control={control}
            register={register}
            initialAdornmentPrice={data?.price?.converted?.amount || '0'}
            initialAdornmentCode={data?.price?.converted?.currency_code || CurrencyCode.UAH}
            watch={watch}
            getValues={getValues}
            rules={{required: {value: true, message: "Поле обов'язкове"}}}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.offer_type}
            items={getNormalisedOptions(offerTypeNames)}
          />
          <Grid item xs={12}>
            <Divider sx={{pt: '12px'}} />
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.row}>
              <Typography variant="body1" sx={{fontWeight: 700}}>
                Інформація про обʼєкт
              </Typography>
              <Controller
                control={control}
                render={({field, fieldState, formState}) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox {...field} defaultChecked={field.value} />}
                      label="Центр"
                      labelPlacement="end"
                    />
                  );
                }}
                name={FormFields['address.is_center']}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxCard} border={1} borderColor="grey.800">
              <Grid container sx={{width: '100%'}} spacing={2}>
                <FieldWidget
                  control={control}
                  register={register}
                  filedName={FormFields['address.city_code']}
                  items={cities?.map((city) => ({
                    label: city.name,
                    value: city.code
                  }))}
                  rules={{
                    required: "Поле обов'язкове."
                  }}
                  gridSize={12}
                />
                <Divider className={styles.deviderInner} />
                <FieldWidget
                  control={control}
                  register={register}
                  items={[]}
                  filedName={FormFields.river}
                  gridSize={4}
                  disabled={true}
                />
                <FieldWidget
                  control={control}
                  register={register}
                  items={[]}
                  filedName={FormFields.underground}
                  gridSize={4}
                  disabled={true}
                />
                <FieldWidget
                  control={control}
                  register={register}
                  items={[]}
                  filedName={FormFields.smallRegion}
                  gridSize={4}
                  disabled={true}
                />
              </Grid>
            </Box>
          </Grid>
          <District
            control={control}
            register={register}
            defaultItems={
              data?.address?.district?.id
                ? [
                    {
                      label: data.address.district?.name,
                      value: data.address.district?.id
                    }
                  ]
                : []
            }
          />
          <Street
            control={control}
            register={register}
            setValue={setValue}
            defaultGeolink={formState?.defaultValues?.['address.geo_link']}
            getValues={getValues}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            defaultItems={
              data?.address?.street_name
                ? [
                    {
                      label: data?.address?.street_name,
                      value: data?.address?.street_name
                    }
                  ]
                : []
            }
          />
          <FieldWidget control={control} register={register} filedName={FormFields['address.building']} gridSize={3} />
          <FieldWidget control={control} register={register} filedName={FormFields['address.section']} gridSize={3} />
          <FieldWidget control={control} register={register} filedName={FormFields['address.entrance']} gridSize={3} />
          <FieldWidget control={control} register={register} filedName={FormFields['address.apartment']} gridSize={3} />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.rooms']}
            gridSize={3}
            items={propertyRooms}
            rules={{
              required: "Поле обов'язкове."
            }}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['address.newly_built']}
            gridSize={3}
            items={[
              {label: 'Новобудова', value: '1'},
              {label: 'Вторинка', value: '0'}
            ]}
          />
          {displayBuildingTypesSelect.toString() === '1' && (
            <AutocompleteWithQuery
              control={control}
              register={register}
              fieldName={FormFields['address.residential_complex']}
              gridSize={6}
              className={classNames(styles.hide, {
                [styles.show]: displayBuildingTypesSelect === '1'
              })}
              multiple={false}
              queryFn={getResidentialComplex}
              queryKey={API_ROUTES.RESIDENTIAL_COMPLEXES}
              rules={{required: {value: true, message: "Поле обов'язкове"}}}
            />
          )}
          {displayBuildingTypesSelect.toString() === '0' && (
            <AutocompleteWithQuery
              control={control}
              register={register}
              fieldName={FormFields['address.building_type']}
              gridSize={6}
              className={classNames(styles.hide, {
                [styles.show]: displayBuildingTypesSelect.toString() === '0'
              })}
              multiple={false}
              queryFn={getBuildingTypes}
              queryKey={API_ROUTES.BUILDING_TYPES}
              rules={{required: {value: true, message: "Поле обов'язкове"}}}
            />
          )}
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.living_area']}
            rules={{
              required: "Поле обов'язкове."
            }}
            gridSize={3}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.kitchen_area']}
            gridSize={3}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.total_floors']}
            rules={{
              required: "Поле обов'язкове."
            }}
            gridSize={3}
            items={floorList}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.floor']}
            gridSize={3}
            items={floorList}
            rules={{
              required: "Поле обов'язкове."
            }}
          />
          <Grid item xs={12}>
            <Divider sx={{pt: '12px'}} />
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.row}>
              <Typography variant="body1" sx={{fontWeight: 700}}>
                Контактна інформація
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxCard} border={1} borderColor="grey.800">
              <Grid container spacing={2}>
                <ContactInfo
                  initialData={data?.contacts || []}
                  control={control}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{pt: '12px'}} />
          </Grid>
          <Grid xs={12} item>
            <Typography variant={'body1'}>Додаткова інформація</Typography>
          </Grid>
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.children']}
            gridSize={6}
            items={getNormalisedOptions(childrenAllowedStatus)}
            multiple={false}
          />
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields['features.pets']}
            gridSize={6}
            items={getNormalisedOptions(petsAllowedStatus)}
            multiple={false}
          />
          {!isParser && (
            <>
              <FieldWidget control={control} register={register} filedName={FormFields.callback_at} gridSize={6} />
              <FieldWidget
                control={control}
                register={register}
                filedName={FormFields.closed_at}
                gridSize={6}
                disabled={true}
              />
            </>
          )}
          <Grid item xs={12}>
            <Divider sx={{pt: '12px'}} />
          </Grid>
          <Grid xs={12} item>
            <Typography variant={'body1'}>Джерела пропозиції </Typography>
          </Grid>
          <Grid item xs={12} sx={{position: 'relative'}}>
            <SourcesInfo
              initialData={data?.sources || []}
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
              unregister={unregister}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sx={{position: 'relative', pb: '32px'}} className={`upload-area`}>
        {hasPendingUploads ? (
          <Box className={styles.sceleton}>
            <CircularProgress size={'32px'} />
            <Typography variant={'h6'}>Процес синхронізації медіа, зачекайте, або перезавантажте сторінку</Typography>
          </Box>
        ) : null}

        <Divider className={styles.deviderVertical} sx={{bgcolor: 'grey.800'}} />
        <Typography variant="h5" className={styles.inner}>
          Галерея
        </Typography>
        <DraggableImageGallery
          images={formatedImagesArray}
          setImages={setPropertyImages}
          showFileLoader={true}
          control={control}
          clearErrors={clearErrors}
        />
      </Grid>
    </Grid>
  );
});

export default PropertyForm;
