import {Typography} from '@mui/material';
import styles from '../listItem/styles.module.scss';
import OpenMap from '../openMap';
import React from 'react';
import {EstateAddress} from '../../../types';

const PropertyAddress: React.FC<{address: EstateAddress; showOnMap?: boolean}> = ({address, showOnMap = true}) => {
  return (
    <>
      <Typography variant={'subtitle1'}>Адреса:</Typography>
      <Typography variant={'subtitle2'} className={styles.adres} sx={{width: '100%'}}>
        {!!Object.entries(address).map(([key, value]) => Boolean(value)).length ? (
          <>
            {address?.city?.name ? <span>{address.city.name}</span> : null}
            <span>
              {address.street_name} {address.building}
            </span>
            {address.residential_complex ? <span>ЖК {address.residential_complex.name}</span> : null}
            {address.building_type ? <span>{address.building_type.name}</span> : null}
            {address.district ? <span>{address.district.name}</span> : null}
          </>
        ) : (
          '-'
        )}
      </Typography>
      {address.geo_link && showOnMap ? <OpenMap address={window.decodeURIComponent(address.geo_link)} /> : null}
    </>
  );
};

export default PropertyAddress;
