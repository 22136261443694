import styles from './styles.module.scss';
import {Box, Button, Checkbox, Dialog, Paper, Typography} from '@mui/material';
import InputField from 'components/common/inputField';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import SelectDropdown from 'components/common/selectDropdown';
import Toast from 'components/common/toast';
import {API_ROUTES} from 'constants/index';
import {postAdmin, updateAdmin} from 'api/admins';
import {useQueryClient} from 'react-query';
import useCity from 'hooks/useCity';
import useAllowedRoles from 'hooks/useUllowedRoles';
type FormValues = {
  cities: string;
  role_id: string;
  email: string;
  first_name: string;
  last_name: string;
  active: boolean;
};
const AdminForm: React.FC<{
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  initialValues?: any;
  adminId?: string;
}> = ({setOpen, open, initialValues, adminId}) => {
  const {cities} = useCity();
  const {allowedRoles} = useAllowedRoles();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    formState: {errors}
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? initialValues
      : {
          cities: '',
          role_id: '',
          email: '',
          first_name: '',
          last_name: '',
          active: false
        }
  });

  const [openToast, setOpenToast] = useState(false);
  const [{toastType, toastTitle, toastBody}, setToastData] = useState<{
    toastType: 'success' | 'error' | 'warning';
    toastTitle: string;
    toastBody: string;
  }>({
    toastType: 'success',
    toastBody: '',
    toastTitle: ''
  });

  const onSubmit = async (data: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    const response = initialValues && adminId ? await updateAdmin(adminId, data) : await postAdmin(data);

    if (!response.success) {
      if (response?.errors && Object.keys(response?.errors)?.length > 0) {
        Object.entries(response.errors).forEach(([key, value]) => {
          setError(key as keyof FormValues, {
            type: 'manual',
            message: Array.isArray(value) ? value[0] : value
          });
        });
      }
      setToastData({
        toastBody: response.message,
        toastTitle: response.success ? 'Успіх' : 'Помилка',
        toastType: response.success ? 'success' : 'error'
      });
      setOpenToast(true);
      return;
    }
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setOpenToast(true);
    setOpen(false);
    await queryClient.invalidateQueries([API_ROUTES.ADMINS]);
  };
  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Typography variant={'h5'} sx={{mb: '24px'}}>
            <strong>Створення запису</strong>
          </Typography>
          <Box className={styles.comment}>
            <div className={styles.row}>
              <InputField
                control={control}
                register={register}
                label={'Імʼя'}
                onChange={() => {}}
                name={'first_name'}
                size={'medium'}
                placeholder={'Імʼя'}
                rules={{
                  required: {value: true, message: "Поле обов'язкове"}
                }}
              />
              <InputField
                control={control}
                register={register}
                label={'Прізвище'}
                placeholder={'Прізвище'}
                onChange={() => {}}
                name={'last_name'}
                size={'medium'}
                rules={{
                  required: {value: true, message: "Поле обов'язкове"}
                }}
              />
            </div>
          </Box>
          <Box className={styles.comment}>
            <div className={styles.row}>
              <InputField
                control={control}
                register={register}
                label={'Email'}
                placeholder={'Email'}
                onChange={() => {}}
                name={'email'}
                size={'medium'}
                rules={{
                  required: {value: true, message: "Поле обов'язкове"},
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Поле повинно email адресою'
                  }
                }}
              />
            </div>
          </Box>
          <Box className={styles.comment}>
            <SelectDropdown
              control={control}
              register={register}
              items={
                Array.isArray(allowedRoles) &&
                allowedRoles.map((role) => ({
                  label: role.name,
                  value: role.id
                }))
              }
              label={'Роль'}
              name={'role_id'}
              size={'small'}
              placeholder={'Виберіть роль'}
              rules={{
                required: {value: true, message: "Поле обов'язкове"}
              }}
            />
          </Box>
          <Box className={styles.comment}>
            <SelectDropdown
              control={control}
              register={register}
              items={cities.map((city) => ({
                label: city.name,
                value: city.code
              }))}
              label={'Місто *'}
              name={'cities'}
              size={'small'}
              placeholder={'Виберіть роль'}
              multiple={true}
              rules={{
                required: {value: true, message: "Поле обов'язкове"}
              }}
            />
          </Box>
          <Box className={styles.comment}>
            <div className={styles.row}>
              <Typography variant={'subtitle1'} sx={{width: 1}}>
                <strong>Активний адмін</strong>
              </Typography>
              <Controller
                render={({field, fieldState, formState}) => {
                  return (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{width: '15px', height: '15px', marginLeft: 'auto'}}
                    />
                  );
                }}
                name={'active'}
                control={control}
              />
            </div>
          </Box>
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              type={'button'}
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length > 0}
            >
              <strong>{initialValues ? 'Оновити' : 'Додати'}</strong>
            </Button>
          </Box>
        </Paper>
      </Dialog>
      <Toast
        open={openToast}
        setOpen={setOpenToast}
        type={toastType}
        message={{
          title: toastTitle,
          body: toastBody
        }}
      />
    </>
  );
};

export default AdminForm;
