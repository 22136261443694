import {Box, Button, Divider, Link, Typography} from '@mui/material';
import styles from './styles.module.scss';
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as ForwardIcon} from 'svg-icons/forward.svg';
import React, {useState} from 'react';
import LayoutTitle from '../../common/layout-title';
import Publish from '../../common/dialogs/publish';
import {useParams} from 'react-router-dom';
import {usePublicationsContext} from '../../../contexts/PublicatiosContext';
import {PublicationStatus} from '../../../types';
import AddManualPost from '../../common/dialogs/add-post';

const PublicationsLayout = ({children}) => {
  const {id: propertyId} = useParams();
  const [repostOpen, setRepostOpen] = useState(false);
  const [postOpen, setPostOpen] = useState(false);
  const {property} = usePublicationsContext();
  return (
    <Box sx={{width: '100%', mt: 0, mb: 'auto'}}>
      <Box
        sx={{
          minWidth: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: '24px',
          position: 'relative',
          bgcolor: 'background.label2',
          marginLeft: '-24px',
          marginRight: '-36px',
          marginBottom: '12px',
          paddingLeft: '24px',
          paddingRight: '36px',
          width: 'auto'
        }}
      >
        <LayoutTitle isParser={false} />
        <Box sx={{display: 'flex', gap: '24px', flexShrink: '0'}}>
          <Button
            variant="outlined"
            className={styles.btn}
            component={Button}
            type="button"
            onClick={() => {
              setPostOpen(true);
            }}
          >
            <AddIcon />
            <span>Додати опублікований пост</span>
          </Button>
          {property?.publication_status === PublicationStatus.PUBLISHED && (
            <Button
              variant="contained"
              className={styles.btn}
              component={Button}
              type="button"
              onClick={() => {
                setRepostOpen(true);
              }}
            >
              <ForwardIcon />
              <span>Зробити репост</span>
            </Button>
          )}
        </Box>
        <Divider className={styles.divider} sx={{ml: '24px'}} />
      </Box>
      <Box>{children}</Box>
      {repostOpen && <Publish open={repostOpen} setOpen={setRepostOpen} propertyId={propertyId} isRepost={true} />}
      {postOpen && <AddManualPost open={postOpen} setOpen={setPostOpen} propertyId={propertyId} />}
    </Box>
  );
};

export default PublicationsLayout;
