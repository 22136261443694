import TimePicker from '../components/common/time-picker';
import InputField from '../components/common/inputField';
import SelectDropdown from '../components/common/selectDropdown';
import InputWithSelect from '../components/common/inputWithSelect';
import AutocompleteInput from '../components/common/autocompleteInput';
import InputMask from '../components/common/inputMask';
import AutocompliteWithDropdown from '../components/common/autocompliteWithDropdown';
import TextAreaField from '../components/common/text-area';

export const smallRegion = [
  {
    label: 'smallRegion1',
    value: 'smallRegion1'
  },
  {
    label: 'smallRegion2',
    value: 'smallRegion2'
  }
];

export const filterFields = {
  comment: {
    label: 'Коментар до обʼєкту',
    Widget: TextAreaField,
    placeholder: '',
    size: 'medium',
    disabled: false
  },
  callback_at: {
    label: 'Передзвонити',
    Widget: TimePicker,
    placeholder: 'Оберіть дату дзвінка',
    size: 'medium',
    disabled: false
  },
  closed_at: {
    label: 'Дата закриття позиції',
    Widget: TimePicker,
    placeholder: 'Оберіть дату',
    size: 'medium',
    disabled: false
  },
  postponed_comment: {
    label: 'Коментар до відкладення',
    Widget: TextAreaField,
    placeholder: '',
    size: 'medium',
    disabled: false
  },
  street_name: {
    label: 'Вулиця *',
    Widget: InputWithSelect,
    placeholder: 'Введіть адресу',
    size: 'medium',
    disabled: false
  },
  street_name_autocomplete: {
    label: 'Вулиця *',
    Widget: AutocompleteInput,
    placeholder: 'Введіть адресу',
    size: 'medium',
    disabled: false
  },
  building: {
    label: 'Номер будинку',
    Widget: InputField,
    placeholder: 'Введіть номер будинку',
    size: 'medium',
    disabled: false
  },
  districts: {
    label: 'Район *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть район',
    size: 'medium',
    disabled: false
  },
  publication_status: {
    label: 'Статус публікації',
    Widget: SelectDropdown,
    placeholder: 'Оберіть Статус',
    size: 'medium',
    disabled: false
  },
  districts_autocomplete: {
    label: 'Район *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть район',
    size: 'medium',
    disabled: false
  },
  apartment: {
    label: 'Квартира',
    Widget: InputField,
    placeholder: 'Введіть номер квартири',
    size: 'medium',
    disabled: false
  },
  section: {
    label: 'Секція / корпус',
    Widget: InputField,
    placeholder: 'Введіть номер секції',
    size: 'medium',
    disabled: false
  },
  entrance: {
    label: 'Підʼїзд',
    Widget: InputField,
    placeholder: "виберіть під'їзд",
    size: 'medium',
    disabled: false
  },
  newly_built: {
    label: 'Тип будівлі *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть тип будівлі',
    size: 'medium',
    disabled: false
  },
  residential_complex: {
    label: 'Назва ЖК *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть тип будівлі',
    size: 'medium',
    disabled: false
  },
  rooms: {
    label: 'Кількість кімнат *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть кількість кімнат',
    size: 'medium',
    disabled: false
  },
  floor: {
    label: 'Поверх квартири',
    Widget: SelectDropdown,
    placeholder: 'Поверх квартири',
    size: 'medium',
    disabled: false
  },
  total_floors: {
    label: 'Поверховість будинку',
    Widget: SelectDropdown,
    placeholder: 'Поверховість будинку',
    size: 'medium',
    disabled: false
  },
  living_area: {
    label: 'Загальна площа (м²)',
    Widget: InputField,
    placeholder: 'Площа',
    size: 'medium',
    disabled: false
  },
  kitchen_area: {
    label: 'Площа кухні (м²)',
    Widget: InputField,
    placeholder: 'Площа кухні (м²)',
    size: 'medium',
    disabled: false
  },
  name: {
    label: 'Імʼя *',
    Widget: InputField,
    placeholder: 'Імʼя',
    size: 'medium',
    disabled: false
  },
  role: {
    label: 'Роль контакту',
    Widget: SelectDropdown,
    placeholder: 'Роль контакту',
    size: 'medium',
    disabled: false
  },
  phone_numbers: {
    label: 'Номер телефону *',
    Widget: InputMask,
    placeholder: 'Номер телефону',
    size: 'medium',
    disabled: false
  },
  children: {
    label: 'Діти',
    Widget: SelectDropdown,
    placeholder: 'Діти',
    size: 'medium',
    disabled: false
  },
  pets: {
    label: 'Тварини',
    Widget: SelectDropdown,
    placeholder: 'Тварини',
    size: 'medium',
    disabled: false
  },
  price: {
    label: 'Ціна *',
    Widget: InputWithSelect,
    placeholder: 'Введіть ціну',
    size: 'medium',
    disabled: false
  },
  price_from: {
    label: 'Ціна від',
    Widget: InputField,
    placeholder: 'Введіть ціну',
    size: 'medium',
    disabled: false
  },
  price_to: {
    label: 'Ціна до',
    Widget: InputField,
    placeholder: 'Введіть ціну',
    size: 'medium',
    disabled: false
  },
  estate_type: {
    label: 'Тип нерухомості *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть тип нерухомості',
    size: 'medium',
    disabled: false
  },
  offer_type: {
    label: 'Тип пропозиції *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть тип пропозиції',
    size: 'medium',
    disabled: false
  },
  property_id: {
    label: 'ID обʼєкту нерухомості',
    Widget: InputField,
    placeholder: 'ID буде згенеровано автоматично',
    size: 'medium',
    disabled: false
  },
  property_id_search: {
    label: 'ID обʼєкту нерухомості',
    Widget: InputField,
    placeholder: 'Введіть ID обʼєкту',
    size: 'medium',
    disabled: false
  },
  last_time_contacted_at: {
    label: 'Дата контакту',
    Widget: TimePicker,
    placeholder: 'Оберіть дату',
    size: 'medium',
    disabled: false
  },
  status: {
    label: 'Актуальність',
    Widget: SelectDropdown,
    placeholder: 'Оберіть статус',
    size: 'medium',
    disabled: false
  },
  admin_id: {
    label: 'Автор додавання',
    Widget: InputField,
    placeholder: "Введіть ім'я автора",
    size: 'medium',
    disabled: false
  },
  author_id: {
    label: 'Автор',
    Widget: SelectDropdown,
    placeholder: 'Оберіть автора',
    size: 'medium',
    disabled: false
  },
  assignee_id: {
    label: 'Асайн',
    Widget: SelectDropdown,
    placeholder: "Введіть ім'я адміна",
    size: 'medium',
    disabled: false
  },
  city: {
    label: 'Місто *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть місто',
    size: 'medium',
    disabled: false
  },
  cities: {
    label: 'Місто *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть місто',
    size: 'medium',
    disabled: false
  },
  last_time_contacted_at_from: {
    label: 'Контакт від',
    Widget: TimePicker,
    placeholder: 'Від',
    size: 'medium',
    disabled: false
  },
  last_time_contacted_at_to: {
    label: 'Контакт до',
    Widget: TimePicker,
    placeholder: 'Від',
    size: 'medium',
    disabled: false
  },
  post_link: {
    label: 'Телеграм пост',
    Widget: InputField,
    placeholder: 'Вставте посилання',
    size: 'medium',
    disabled: false
  },
  source: {
    label: 'Ресурс *',
    Widget: SelectDropdown,
    placeholder: 'Вкажіть ресурс',
    size: 'medium',
    disabled: false
  },
  source_link: {
    label: 'Посилання на ресурс',
    Widget: InputField,
    placeholder: 'Вкажіть Посилання',
    size: 'medium',
    disabled: false
  },
  source_id: {
    label: 'ID ресурсу',
    Widget: InputField,
    placeholder: 'Введіть ID ресурсу',
    size: 'medium',
    disabled: false
  },
  river: {
    label: 'Берег Дніпра',
    Widget: SelectDropdown,
    placeholder: 'Вкажіть берег',
    size: 'medium',
    disabled: false
  },
  underground: {
    label: 'Станція метро',
    Widget: SelectDropdown,
    placeholder: 'Виберіть станцію метро',
    size: 'medium',
    disabled: false
  },
  smallRegion: {
    label: 'Мікрорайон',
    Widget: SelectDropdown,
    placeholder: 'Оберіть мікрорайон',
    size: 'medium',
    disabled: false
  },
  residential_complexes: {
    label: 'Назва ЖК *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть тип будівлі',
    size: 'medium',
    disabled: false
  },
  building_types: {
    label: 'Тип вторинки *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть тип вторинки',
    size: 'medium',
    disabled: false
  },
  living_area_from: {
    label: 'Площа від (м²)',
    Widget: InputField,
    placeholder: 'Від',
    size: 'medium',
    disabled: false
  },
  living_area_to: {
    label: 'Площа до (м²)',
    Widget: InputField,
    placeholder: 'До',
    size: 'medium',
    disabled: false
  },
  floor_from: {
    label: 'Поверх від',
    Widget: InputField,
    placeholder: 'Від',
    size: 'medium',
    disabled: false
  },
  floor_to: {
    label: 'Поверх До',
    Widget: InputField,
    placeholder: 'До',
    size: 'medium',
    disabled: false
  },
  'features.rooms': {
    label: 'Кількість кімнат *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть кількість кімнат',
    size: 'medium',
    disabled: false
  },
  'features.living_area': {
    label: 'Загальна площа (м²) *',
    Widget: InputField,
    placeholder: 'Площа',
    size: 'medium',
    disabled: false
  },
  'features.kitchen_area': {
    label: 'Площа кухні (м²)',
    Widget: InputField,
    placeholder: 'Площа кухні (м²)',
    size: 'medium',
    disabled: false
  },
  'features.total_floors': {
    label: 'Поверховість будинку*',
    Widget: SelectDropdown,
    placeholder: 'Поверховість будинку',
    size: 'medium',
    disabled: false
  },
  'features.floor': {
    label: 'Поверх квартири *',
    Widget: SelectDropdown,
    placeholder: 'Поверх квартири',
    size: 'medium',
    disabled: false
  },
  'features.children': {
    label: 'Діти',
    Widget: SelectDropdown,
    placeholder: 'Діти',
    size: 'medium',
    disabled: false
  },
  'features.pets': {
    label: 'Тварини',
    Widget: SelectDropdown,
    placeholder: 'Тварини',
    size: 'medium',
    disabled: false
  },
  'address.city_code': {
    label: 'Місто *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть місто',
    size: 'medium',
    disabled: false
  },
  'address.district': {
    label: 'Район *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть район',
    size: 'medium',
    disabled: false
  },
  'address.residential_complex': {
    label: 'Назва ЖК *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть тип будівлі',
    size: 'medium',
    disabled: false
  },
  'address.building_type': {
    label: 'Тип вторинки *',
    Widget: AutocompleteInput,
    placeholder: 'Оберіть тип вторинки',
    size: 'medium',
    disabled: false
  },
  'address.street_name': {
    label: 'Вулиця *',
    Widget: AutocompliteWithDropdown,
    placeholder: 'Введіть адресу',
    size: 'medium',
    disabled: false
  },
  'address.newly_built': {
    label: 'Тип будівлі *',
    Widget: SelectDropdown,
    placeholder: 'Оберіть тип будівлі',
    size: 'medium',
    disabled: false
  },
  'address.building': {
    label: 'Номер будинку',
    Widget: InputField,
    placeholder: 'Введіть номер будинку',
    size: 'medium',
    disabled: false
  },
  'address.section': {
    label: 'Секція / корпус',
    Widget: InputField,
    placeholder: 'Введіть номер секції',
    size: 'medium',
    disabled: false
  },
  'address.entrance': {
    label: 'Підʼїзд',
    Widget: InputField,
    placeholder: "виберіть під'їзд",
    size: 'medium',
    disabled: false
  },
  'address.apartment': {
    label: 'Квартира',
    Widget: InputField,
    placeholder: 'Введіть номер квартири',
    size: 'medium',
    disabled: false
  },
  'address.geo_link': {
    label: '',
    Widget: InputField,
    placeholder: 'Посилання на локацію',
    size: 'medium',
    disabled: false
  }
};
