import React, {FC, useState} from 'react';
import {Box, Chip, Link, Typography} from '@mui/material';
import TableGreed from '../../common/table';
import {IPublication, ITableColumns, PublicationState} from '../../../types';
import {API_ROUTES, publicationStateNames} from '../../../constants';
import {getPublication} from '../../../api/base';
import {useParams} from 'react-router-dom';
import PostActions from './publication-actions';
import {formatDateString} from '../../../helpers';
import PublicationsLayout from '../../Layouts/publications-layout';
import {PublicationsContextProvider} from '../../../contexts/PublicatiosContext';

const rows: ITableColumns<IPublication>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: false
  },
  {
    title: 'Посилання',
    dataIndex: 'post_link',
    key: 'post_link',
    sortable: false,
    render: (item, value) => {
      return (
        <Link href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </Link>
      );
    }
  },
  {
    title: 'Статус',
    dataIndex: 'state',
    key: 'state',
    sortable: false,
    render: (item, value: PublicationState) => {
      return (
        <Chip
          label={publicationStateNames[value]}
          variant="outlined"
          sx={{bgcolor: `background.chip.${value}`, borderRadius: '4px'}}
        />
      );
    }
  },
  {
    title: 'Тип публікації',
    dataIndex: 'is_original',
    key: 'is_original',
    render: (item, value) => {
      return (
        <Chip
          label={value ? 'Оригінал' : 'Репост'}
          variant="outlined"
          sx={{ bgcolor: value ? `background.chip.original` : `background.chip.unoriginal`, borderRadius: '4px' }}
        />
      );
    },
    sortable: false
  },
  {
    title: 'Дата публікації',
    dataIndex: 'posted_at',
    key: 'posted_at',
    sortable: false,
    render: (item, value) => {
      return (
        <Typography variant={'caption'} sx={{color: 'text.primary'}}>
          {value ? formatDateString(value, 'YYYY-MM-DD, HH:mm') : '-'}
        </Typography>
      );
    }
  },
  {
    title: 'Дії',
    dataIndex: '',
    key: '',
    render: (item) => {
      const canDelete = item.policies?.can_delete;
      return canDelete ? <PostActions publicationId={item.id} /> : null;
    },
    sortable: false
  }

];

const mockData = [
  {
    id: '1',
    name: 'Name',
    event_type: 'Anniversary work',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00'
  },
  {
    id: '2',
    name: 'Name2',
    event_type: 'Anniversary work',
    status: 'Skipped',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00'
  },
  {
    id: '3',
    name: 'Name3',
    event_type: 'Birthday',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00'
  }
];

const PublicationsList: FC = () => {
  const [take, setTake] = useState(10);
  const {id: propertyId} = useParams();
  return (
    <PublicationsContextProvider>
      <PublicationsLayout>
        <Box sx={{height: 400, width: '100%'}}>
          <TableGreed<any>
            columns={rows}
            requestUrl={API_ROUTES.ADMINS}
            defaultSorting={{name: 'created_at', direction: 'asc'}}
            take={take}
            //@ts-ignore
            fetchFn={getPublication}
            propertyId={propertyId}
            mockData={mockData}
            requestKey={'publications'}
          />
        </Box>
      </PublicationsLayout>
    </PublicationsContextProvider>
  );
};

export default PublicationsList;
