import React from 'react';
import {Box, Button, Link, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PROPERTY_URL} from '../../../constants/routes';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const LayoutTitle: React.FC<{isParser: boolean}> = ({isParser}) => {
  const {filter: filterName, id: propertyId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isPublications = location.pathname.includes('publications');
  const showButtonWrapper = filterName === 'active' || filterName === 'unpublished';

  return isParser ? (
    <Typography variant="h4">Про об'єкт</Typography>
  ) : (
    <Box className={styles.container}>
      <Button
        component={Link}
        href={`${PROPERTY_URL}/${filterName}`}
        sx={{mr: '12px', bgcolor: 'background.label', color: 'text.primary'}}
        className={styles.btn}
      >
        <ArrowBackRoundedIcon />
      </Button>
      {showButtonWrapper && (
        <Box className={styles.wrapper} sx={{bgcolor: 'background.default'}}>
          <Button
            component={Link}
            variant={isPublications ? 'text' : 'contained'}
            href={`${PROPERTY_URL}/${filterName}/${propertyId}`}
            sx={{color: isPublications ? 'text.customGray' : 'text.dark'}}
          >
            <strong>Про обʼєкт</strong>
          </Button>
          <Button
            component={Link}
            variant={isPublications ? 'contained' : 'text'}
            href={`${PROPERTY_URL}/${filterName}/${propertyId}/publications`}
            sx={{color: isPublications ? 'text.dark' : 'text.customGray'}}
          >
            <strong> Публікації</strong>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LayoutTitle;
