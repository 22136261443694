import { IconButton, Snackbar } from '@mui/material';
import { ReactComponent as CopyIcon } from 'svg-icons/copy.svg';
import { useState } from 'react';

interface ClipboardCopyProps {
  value: string;
}

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({ value }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (value) {
      setOpen(true);
      navigator.clipboard.writeText(value).then(() => {
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} color="primary" sx={{ padding: '4px' }}>
        <CopyIcon />
      </IconButton>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default ClipboardCopy;
