import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardMedia, Button, Box} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './styles.module.scss';
import LiteboxSlider from '../liteboxSlider';
import FileUpload from '../file-loader';
import {mergeArrays} from '../../../helpers';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';
import NoImage from '../no-image';
import {ListManager} from 'react-beautiful-dnd-grid';

const DraggableImageGallery = ({images, setImages, showFileLoader = false, control, clearErrors}) => {
  const [{open, slideIndex}, setOpen] = useState({open: false, slideIndex: 0});
  const {setFormContextChanged} = useFormWatcherContext();

  const reorder = (list) => {
    const items = list.sortedList.slice().sort((first, second) => first.order - second.order);
    setImages(items);
    setFormContextChanged(true);
    return items;
  };

  const [sortedList, setSortedList] = useState({sortedList: []});

  const sortList = () => {
    setSortedList((state) => ({
      ...state,
      sortedList: reorder(state)
    }));
  };

  useEffect(() => {
    setSortedList({sortedList: images});
  }, [images]);

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedList.sortedList;

    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      sortList();
      return;
    }

    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      sortList();
      return;
    }

    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      sortList();
      return;
    }

    list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    sortList();
  };

  const handleUploadSuccess = (data) => {
    setImages((prevImages) => mergeArrays(prevImages, data));
  };

  const onCrossClick = (id) => {
    const newImages = images.filter((img) => img.uuid !== id);
    setImages(newImages);
    setFormContextChanged(true);
  };

  const ListElement = useCallback(
    ({item, index}) => {
      return (
        <Card sx={{position: 'relative'}} className={styles.card}>
          <Button
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              minWidth: 0,
              minHeight: 0,
              padding: 0,
              zIndex: 2
            }}
            onClick={() => onCrossClick(item.uuid)}
          >
            <CancelIcon
              sx={{
                borderRadius: '50%',
                width: 15,
                height: 15,
                backgroundColor: 'red',
                color: '#fff'
              }}
            />
          </Button>
          <CardMedia
            onClick={() =>
              setOpen((state) => ({
                open: true,
                slideIndex: index
              }))
            }
            component="img"
            image={item.url}
            alt={`Image ${index}`}
          />
        </Card>
      );
    },
    [onCrossClick]
  );
  return (
    <div className={styles.list}>
      {showFileLoader && (
        <FileUpload onUploadSuccess={handleUploadSuccess} control={control} clearErrors={clearErrors} />
      )}
      {images?.length > 0 ? (
        <ListManager
          items={sortedList.sortedList}
          direction="horizontal"
          maxItems={3}
          render={(item) => {
            return <ListElement item={item} />;
          }}
          onDragEnd={reorderList}
        />
      ) : (
        <Box className={styles.noImage}>
          <NoImage />
        </Box>
      )}

      <LiteboxSlider images={images} isOpen={open} setIsOpen={setOpen} index={slideIndex} />
    </div>
  );
};

export default DraggableImageGallery;
