import styles from './styles.module.scss';
import {Box, CircularProgress, Typography} from '@mui/material';
import React from 'react';
import {ReactComponent as Cactus} from 'svg-icons/cactus.svg';

const NoData: React.FC<{isLoading: boolean}> = ({isLoading}) => {
  return (
    <Box className={styles.noData}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Cactus widths={56} height={56} />
          <Typography variant={'h6'}>
            <strong>Записів не знайдено</strong>
          </Typography>
          <Typography variant={'caption'}></Typography>
        </>
      )}
    </Box>
  );
};

export default NoData;
