import React, {useState} from 'react';
import {Box, CircularProgress, FormHelperText, Link, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as CloudIcon} from 'svg-icons/cloud.svg';
import {postTemporaryMedia} from '../../../api/media';
import {useToastContext} from '../../../contexts/ToastContext';
import {IPropertyMedia} from '../../../types';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';
import {Controller} from 'react-hook-form';

const FileUpload: React.FC<{
  onUploadSuccess: (data: IPropertyMedia[]) => void;
  control: any;
  clearErrors: any;
}> = ({onUploadSuccess, control, clearErrors}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const {setFormContextChanged} = useFormWatcherContext();
  const [loading, setLoading] = useState(false);

  const onUploaded = async (files: File[]) => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('media[]', file, file.name);
    });

    try {
      const data = await postTemporaryMedia(formData);
      onUploadSuccess(data);
      setFormContextChanged(true);
    } catch (error: any) {
      setOpenToast(true);
      if (error.handleError) {
        setToastData({
          toastBody: error.errors.message,
          toastTitle: 'Помилка',
          toastType: 'error'
        });

        setOpenToast(true);
      }
    }
    setLoading(false);
  };

  const splitFilesIntoBatches = (files: File[]) => {
    const batches: File[][] = [];
    while (files.length) {
      batches.push(files.splice(0, 5));
    }
    return batches;
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files: File[] = Array.from(e.dataTransfer.files);

    if (files.length > 5) {
      const batches = splitFilesIntoBatches(files);
      batches.forEach((batch) => {
        onUploaded(batch);
      });
      return;
    }

    onUploaded(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);

    if (files.length > 5) {
      const batches = splitFilesIntoBatches(files);
      batches.forEach((batch) => {
        onUploaded(batch);
      });
      return;
    }

    onUploaded(files);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const files = Array.from(e.clipboardData.files || []);

    if (files.length > 5) {
      const batches = splitFilesIntoBatches(files);
      batches.forEach((batch) => {
        onUploaded(batch);
      });
      return;
    }

    onUploaded(files);
  };

  React.useEffect(() => {
    const handleGlobalPaste = (e: ClipboardEvent) => {
      if (e.target && e.target instanceof HTMLElement && e.target.closest('.upload-area')) {
        const files = Array.from(e.clipboardData?.files || []);

        if (files.length > 5) {
          const batches = splitFilesIntoBatches(files);
          batches.forEach((batch) => onUploaded(batch));
          return;
        }

        onUploaded(files);
      }
    };

    document.addEventListener('paste', handleGlobalPaste);
    return () => {
      document.removeEventListener('paste', handleGlobalPaste);
    };
  }, [onUploaded, splitFilesIntoBatches]);

  return (
    <Box
      className={styles.wrapper}
      tabIndex={0}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onFileDrop}
    >
      {loading ? (
        <CircularProgress size={'32px'} />
      ) : (
        <>
          <CloudIcon />
          <Controller
            render={({ field, fieldState, formState }) => (
              <Box>
                <input
                  {...field}
                  type="file"
                  onChange={handleFileChange}
                  onPaste={handlePaste}
                  multiple
                  onClick={() => {
                    clearErrors('media');
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: formState?.errors?.['media'] ? 'text.error' : 'text.primary',
                  }}
                >
                  {formState?.errors?.['media'] ? (
                    <FormHelperText sx={{ color: 'error.main', textAlign: 'center' }}>
                      <>
                        {
                          //@ts-ignore
                          formState?.errors?.['media']?.['0']
                        }
                      </>
                      <>Завантажте зображення</>
                    </FormHelperText>
                  ) : (
                    <>
                      Перетягніть картинку, или{' '}
                      <Link component="div">завантажте з компʼютера</Link>
                    </>
                  )}
                </Typography>
              </Box>
            )}
            name="media"
            control={control}
          />

        </>
      )}
    </Box>
  );
};

export default FileUpload;
