import PropertyPage from '../../common/property';
import React from 'react';
import {Typography} from '@mui/material';

const PropertyCreate: React.FC<any> = () => {
  return (
    <PropertyPage title={<Typography variant="h4">Створити об'єкт</Typography>} type={'create'} isParser={false} />
  );
};

export default PropertyCreate;
