import {Card, List, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {useMemo} from 'react';
import {
  PROPERTY_URL,
  CLIENTS_BASE_URL,
  PARSER_BASE_URL,
  STATISTICS_BASE_URL,
  SYSTEM_BASE_URL,
  TENANTS_BASE_URL
} from '../../constants/routes';
import {ReactComponent as ParserSvg} from 'svg-icons/parser.svg';
import {ReactComponent as AdIcon} from 'svg-icons/adBase.svg';
import {ReactComponent as BookUser} from 'svg-icons/bookUser.svg';
import {ReactComponent as Monitor} from 'svg-icons/monitor.svg';
import {ReactComponent as Chart} from 'svg-icons/chart.svg';
import {ReactComponent as User} from 'svg-icons/userRoundCheck.svg';
import SidebarListItem from './sidebarListItem';
import {useUser} from '../../hooks/useUser';

const Sidebar = () => {
  const {user} = useUser();

  const menuList = useMemo(
    () => [
      {
        name: 'Парсер',
        routPath: '/',
        key: 'parser',
        Icon: (props: any) => <ParserSvg {...props} />,
        children: [
          {
            name: "Мої об'єкти",
            routPath: `${PARSER_BASE_URL}/all`
          },
          {
            name: "Всі нові об'єкти",
            routPath: `${PARSER_BASE_URL}/new`
          },
          {
            name: 'Від власників',
            routPath: `${PARSER_BASE_URL}/owners`
          },
          {
            name: 'Ціна вище',
            routPath: `${PARSER_BASE_URL}/price-higher`
          },
          {
            name: 'Ціна нижче',
            routPath: `${PARSER_BASE_URL}/price-lower`
          },
          {
            name: 'Воскреслі з архіву',
            routPath: `${PARSER_BASE_URL}/revived-from-archive`
          },
          {
            name: 'Відкладені',
            routPath: `${PARSER_BASE_URL}/postponed`
          },
          {
            name: 'Смітник',
            routPath: `${PARSER_BASE_URL}/trash`
          }
        ]
      },
      {
        name: 'База',
        routPath: `${PROPERTY_URL}`,
        key: 'adBase',
        Icon: (props) => <AdIcon {...props} />,
        children: [
          {
            name: 'Неопубліковані',
            routPath: `${PROPERTY_URL}/unpublished`
          },
          {
            name: 'Активні',
            routPath: `${PROPERTY_URL}/active`
          },
          {
            name: 'Архівні',
            routPath: `${PROPERTY_URL}/archived`
          }
        ]
      },
      {
        name: 'Орендатори',
        routPath: `${TENANTS_BASE_URL}`,
        key: 'tenants',
        Icon: (props) => <BookUser {...props} />,
        children: []
      },
      {
        name: 'Клієнти',
        routPath: `${CLIENTS_BASE_URL}`,
        key: 'clients',
        Icon: (props) => <User {...props} />,
        children: []
      },
      {
        name: 'Статистика',
        routPath: `${STATISTICS_BASE_URL}`,
        key: 'clients',
        Icon: (props) => <Chart {...props} />,
        children: []
      },
      {
        name: 'Система',
        routPath: `${SYSTEM_BASE_URL}`,
        key: 'clients',
        Icon: (props) => <Monitor {...props} />,
        children: [
          {
            name: 'Адміни',
            routPath: `${SYSTEM_BASE_URL}/admins`
          }
        ]
      }
    ],
    [user]
  );

  return (
    <Card className={styles.sidebarWrapper} sx={{bgcolor: 'background.secondary', position: 'sticky'}}>
      <List
        component="nav"
        aria-labelledby="nested-list-navigation"
        subheader={<Typography variant="h6">Menu</Typography>}
      >
        <SidebarListItem data={menuList} />
      </List>
    </Card>
  );
};

export default Sidebar;
