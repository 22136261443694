import {API_ROUTES} from '../../constants';
import api from '../server';
import {
  HandleError,
  IPropertyMedia,
  IPublication,
  PropertyListItemType,
  PublicationStatus,
  ResponseWithPagination
} from '../../types';

export async function createFromParsed(data: {parsed_property_id: string}): Promise<
  | {
      success: boolean;
      message: string;
      data: {
        property_id: string;
      };
    }
  | HandleError
> {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES_FROM_PARSED}`, data);
    return {
      success: true,
      ...response.data
    };
  } catch (error: any) {
    if (error.handleError) {
      throw error;
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
}

export const putProperties = async (propertyId: string, data: any) => {
  try {
    const response = await api.put(`${API_ROUTES.PROPERTIES}/${propertyId}/`, data);
    return {
      success: true,
      message: response.data.message
    };
  } catch (error: any) {
    if (error.handleError) {
      throw error;
    }
    console.log(error);
  }
};

export const postProperties = async (data: any) => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES}`, data);
    return {
      success: true,
      message: response.data.message
    };
  } catch (error: any) {
    if (error.handleError) {
      throw error;
    }
    console.log(error);
  }
};

export const getBaseProperties = async (params: any): Promise<ResponseWithPagination<PropertyListItemType[]>> => {
  try {
    const response = await api.get(API_ROUTES.PROPERTIES, {params});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBaseProperty = async ({id}: {id: string}): Promise<PropertyListItemType> => {
  try {
    const response = await api.get(`${API_ROUTES.PROPERTIES}/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const archiveProperty = async (data: {
  property_id: string;
  status: string;
  comment: string;
}): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES_ARCHIVED}`, data);
    return {
      success: true,
      ...response.data
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const deletePropertyFromArchive = async (property_id: string): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.delete(`${API_ROUTES.PROPERTIES_ARCHIVED}`, {
      data: {
        property_id
      }
    });
    return {
      success: true,
      ...response.data
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const deleteProperty = async (property_id: string): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.delete(`${API_ROUTES.PROPERTIES}/${property_id}`);
    return {
      success: true,
      ...response.data
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const getPublishTemplate = async ({
  id
}: {
  id: string;
}): Promise<{success: boolean; message?: string; media?: IPropertyMedia[]; template?: string}> => {
  try {
    const response = await api.get(`${API_ROUTES.PROPERTIES}/${id}/publications/template`);
    return {
      success: true,
      ...response.data.data
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const postPublication = async (
  propertyId: string,
  data: {
    post_content: string;
    postponed: boolean;
    scheduled_at: string;
  }
): Promise<{success: boolean; message?: string}> => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES}/${propertyId}/publications`, data);
    return {
      success: true,
      message: response.data?.message
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const repostPublication = async (
  propertyId: string,
  data: {
    post_content: string;
    postponed: boolean;
    scheduled_at: string;
  }
): Promise<{success: boolean; message?: string}> => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES}/${propertyId}/reposts`, data);
    return {
      success: true,
      message: response.data?.message
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const postManualPublication = async (
  propertyId: string,
  data: {
    post_link: string;
    is_original: boolean;
    contacted_with_owner: boolean;
  }
): Promise<{success: boolean; message?: string}> => {
  try {
    const response = await api.post(`${API_ROUTES.PROPERTIES}/${propertyId}/publications/manual`, data);
    return {
      success: true,
      message: response.data?.message
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const getPublication = async (
  params: {
    cursor: string;
  },
  propertyId: string
): Promise<{
  success: boolean;
  message?: string;
  data?: IPublication[];
  property?: {archived: boolean; id: string; publication_status: PublicationStatus};
}> => {
  try {
    const response = await api.get(`${API_ROUTES.PROPERTIES}/${propertyId}/publications`, {params});
    return {
      success: true,
      data: response?.data.data.publications,
      property: response.data.data.property
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const removePublication = async (
  propertyId: string,
  publicationId: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  try {
    const response = await api.delete(`${API_ROUTES.PROPERTIES}/${propertyId}/publications/${publicationId}`);
    return {
      success: true,
      message: response?.data.message
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};

export const putPublicationStatus = async (
  propertyId: string,
  data: {
    publication_status: string;
  }
): Promise<{success: boolean; message: string}> => {
  try {
    const response = await api.put(`${API_ROUTES.PROPERTIES}/${propertyId}/publication_status`, data);
    return {
      success: true,
      message: response.data?.message
    };
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
};
