import {Box, Grid, Link, Tooltip, Typography} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import {EstateSource, PropertyLabels, RawEstateContacts} from 'types';
import { propertySourceNames } from 'constants/index';
import {
  convertTimeAgo,
  formatCustomTime,
} from 'helpers';
import ListItemLabels from '../../list-item-labels';

const PropertyItemFooter: React.FC<{
  propertyId: string;
  sources: EstateSource;
  latestPublicationAt: string;
  lastTimeContactedAt: string | null;
  createdAt: string;
  contacts: RawEstateContacts[];
  showLabels?: boolean;
  labels: PropertyLabels[];
  offerType: string;
  isMergeItem?: boolean;
  isBase: boolean;
}> = ({
  sources,
  latestPublicationAt,
  lastTimeContactedAt,
  createdAt,
  propertyId,
  showLabels = true,
  labels,
  offerType,
  isMergeItem = false,
  isBase
}) => {
  const source = Array.isArray(sources) ? sources.find((source) => source.primary) : sources;

  return (
    <Grid container spacing={4} className={styles.wrapper}>
      {showLabels && (
        <Grid item className={styles.col}>
          <ListItemLabels labels={labels} offerType={offerType} />
        </Grid>
      )}
      <Grid item className={styles.col}>
        <Box className={styles.item}>
          <Typography variant={'caption'} sx={{color: 'text.primary'}}>
            ID об'єкту:
          </Typography>
          <Typography variant={'caption'}>{propertyId}</Typography>
        </Box>
        {source && source.id && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              {
                //@ts-ignore
                propertySourceNames[source.type]
              }{' '}
              ID:
            </Typography>
            <Typography variant={'caption'}>{source.id}</Typography>
          </Box>
        )}
        {latestPublicationAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Опубліковано:
            </Typography>
            <Tooltip disableInteractive title={formatCustomTime(latestPublicationAt)} arrow>
              <Typography variant={'caption'}>
                {convertTimeAgo(latestPublicationAt)}
              </Typography>
            </Tooltip>
          </Box>
        )}
        {!!lastTimeContactedAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Дата контакту:
            </Typography>
            <Typography variant={'caption'}>
              {Array.isArray(lastTimeContactedAt) ? (
                <Tooltip disableInteractive title={formatCustomTime(lastTimeContactedAt, 'DD.MM.YYYY')} arrow>
                  <Typography variant={'caption'}>
                    lastTimeContactedAt
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip disableInteractive title={formatCustomTime(lastTimeContactedAt, 'DD.MM.YYYY')} arrow>
                  <Typography variant={'caption'}>
                    {convertTimeAgo(lastTimeContactedAt)}
                  </Typography>
                </Tooltip>
              )}
            </Typography>
          </Box>
        )}
        {!isMergeItem && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              {isBase ? 'Додано' : 'Парсінг'}:
            </Typography>
            <Tooltip disableInteractive title={formatCustomTime(createdAt)} arrow>
              <Link component={'div'} variant={'caption'} sx={{color: 'text.success', textDecoration: 'none'}}>
                {convertTimeAgo(createdAt)}
              </Link>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default PropertyItemFooter;
