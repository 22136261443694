import {useEffect, useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {useSearchParams, useParams, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import ParsersContentLayout from 'components/Layouts/parsersContentLayout';
import Pagination from 'components/common/pagination';
import {useUser} from 'hooks/useUser';
import Loader from 'components/common/loader';
import {useFilterContext} from 'contexts/FiltersContext';
import {PropertyListItemType} from 'types';
import PropertyItem from 'components/common/listItem';
import {getBaseProperties} from '../../../api/base';
import NoData from '../../common/no-data';
import {PARSER_BASE_URL} from '../../../constants/routes';
import {defaultPropertiesFilterValues} from '../../../constants';

type IFilter = Record<string, {title: string; search: Record<string, any>}>;

const PropertyListPage = () => {
  const {filters, setFilterErrors, setFilters} = useFilterContext();
  const hasAnyFilters = Object.keys(filters).length > 0;
  const propertyFilterConfig: IFilter = useMemo(
    () => ({
      unpublished: {
        title: 'Неопубліковані',
        search: {
          publication_statuses: ['do_not_publish', 'unpublished', 'publishing'],
          status: 'active',
          archived: false,
          ...filters
        }
      },
      active: {
        title: 'Активні',
        search: {
          publication_statuses: ['published', 'postponed'],
          status: 'active',
          archived: false,
          ...filters
        }
      },
      archived: {
        title: 'Архів',
        search: {
          archived: true,
          ...filters
        }
      }
    }),
    [filters, hasAnyFilters]
  );

  const {filter: filterName = ''} = useParams();
  const navigate = useNavigate();
  const filter: {title: string; search: Record<string, any>} = propertyFilterConfig[filterName];

  const pageSearchParams = filter?.search;
  const title = filter?.title;
  let [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('cursor') || '';

  const {
    data: resultData,
    isLoading,
    isSuccess,
    refetch,
    error
  } = useQuery(
    ['property-base', pageSearchParams, cursor],
    () =>
      getBaseProperties({
        cursor,
        ...pageSearchParams
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (!filter) {
      navigate(PARSER_BASE_URL);
    }
  }, [filter, navigate]);

  useEffect(() => {
    if (!error) {
      return;
    }
    // @ts-ignore
    setFilterErrors(error.response?.data?.errors);
  }, [error]);

  useEffect(() => {
    return () => {
      setFilters(defaultPropertiesFilterValues);
    };
  }, []);

  const data: PropertyListItemType[] = resultData?.data || [];
  const isSearchFinishedAndResultsEmpty = useMemo(() => isSuccess && !data.length, [data.length, isSuccess]);

  return (
    <ParsersContentLayout title={title} isLoading={isLoading}>
      <>
        {isSearchFinishedAndResultsEmpty && <NoData isLoading={false} />}
        {isLoading && <Loader size={'32px'} />}

        {!isSearchFinishedAndResultsEmpty && !isLoading && (
          <>
            <Grid container spacing={1} sx={{py: 4}}>
              {Array.isArray(data) &&
                data?.map((itemDetails: PropertyListItemType) => {
                  return (
                    <PropertyItem
                      item={itemDetails}
                      key={itemDetails.id}
                      filter={filter}
                      refetch={refetch}
                      parentList={'base'}
                    />
                  );
                })}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pb: '2rem'
              }}
            >
              <Pagination cursor={resultData?.cursor} />
            </Box>
          </>
        )}
      </>
    </ParsersContentLayout>
  );
};

export default PropertyListPage;
