import React from 'react';
import {Grid} from '@mui/material';
import styles from './styles.module.scss';
import {FormFields, ISelectItem} from '../../../types';
import {InputSizes} from '../inputField';
import {filterFields} from '../../../mock';
import classNames from 'classnames';
import {Dayjs} from 'dayjs';

const FieldWidget: React.FC<{
  control: any;
  register: any;
  filedName: keyof typeof FormFields;
  items?: ISelectItem[];
  gridSize?: number;
  className?: string;
  onChange?: (name: string, value: any) => void;
  showEndAdornment?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  rules?: Record<string, any>;
  sx?: any;
  startAdornmentItems?: ISelectItem[];
  EndAdornment?: React.ReactNode;
  maxDate?: Dayjs | null;
  label?: string | null;
}> = ({
  control,
  register,
  filedName,
  items = [],
  gridSize = 6,
  className = '',
  onChange = () => {},
  disabled = false,
  multiple = false,
  rules = {},
  sx = {},
  startAdornmentItems = [],
  EndAdornment = null,
  showEndAdornment = false,
  maxDate = null,
  label = null
}) => {
  //@ts-ignore
  const config: Record<string, any> = filterFields[filedName];
  const Widget = config.Widget;

  return (
    <Grid item xs={gridSize} className={classNames(styles.row, className)}>
      <Widget
        control={control}
        label={label ?? config.label}
        name={filedName}
        placeholder={config.placeholder}
        onChange={onChange}
        size={config.size as InputSizes}
        items={items}
        register={register}
        disabled={disabled}
        EndAdornment={EndAdornment}
        multiple={multiple}
        rules={rules}
        sx={sx}
        startAdornmentItems={startAdornmentItems}
        showEndAdornment={showEndAdornment}
        maxDate={maxDate}
      />
    </Grid>
  );
};

export default FieldWidget;
