export enum FormFields {
  'comment' = 'comment',
  'postponed_comment' = 'postponed_comment',
  'closed_at' = 'closed_at',
  'assignee_id' = 'assignee_id',
  'author_id' = 'author_id',
  'apartment' = 'apartment',
  'callback_at' = 'callback_at',
  'price' = 'price',
  'price_from' = 'price_from',
  'price_to' = 'price_to',
  'property_id' = 'property_id',
  'property_id_search' = 'property_id_search',
  'publication_status' = 'publication_status',
  'estate_type' = 'estate_type',
  'offer_type' = 'offer_type',
  'last_time_contacted_at' = 'last_time_contacted_at',
  'last_time_contacted_at_from' = 'last_time_contacted_at_from',
  'last_time_contacted_at_to' = 'last_time_contacted_at_to',
  'status' = 'status',
  'admin_id' = 'admin_id',
  'city' = 'city',
  'cities' = 'cities',
  'post_link' = 'post_link',
  'source' = 'source',
  'source_id' = 'source_id',
  'source_link' = 'source_link',
  'section' = 'section',
  'river' = 'river',
  'underground' = 'underground',
  'smallRegion' = 'smallRegion',
  'districts' = 'districts',
  'districts_autocomplete' = 'districts_autocomplete',
  'street_name' = 'street_name',
  'street_name_autocomplete' = 'street_name_autocomplete',
  'newly_built' = 'newly_built',
  'residential_complexes' = 'residential_complexes',
  'residential_complex' = 'residential_complex',
  'building_types' = 'building_types',
  'building' = 'building',
  'rooms' = 'rooms',

  'living_area' = 'living_area',
  'living_area_from' = 'living_area_from',
  'living_area_to' = 'living_area_to',
  'kitchen_area' = 'kitchen_area',
  'floor' = 'floor',
  'floor_from' = 'floor_from',
  'floor_to' = 'floor_to',
  'total_floors' = 'total_floors',
  'name' = 'name',
  'role' = 'role',
  'phone_numbers' = 'phone_numbers',
  'children' = 'children',
  'pets' = 'pets',
  'entrance' = 'entrance',
  'is_center' = 'is_center',
  'features.rooms' = 'features.rooms',
  'features.living_area' = 'features.living_area',
  'features.kitchen_area' = 'features.kitchen_area',
  'features.total_floors' = 'features.total_floors',
  'features.floor' = 'features.floor',
  'features.children' = 'features.children',
  'features.pets' = 'features.pets',
  'address.city_code' = 'address.city_code',
  'address.district' = 'address.district',
  'address.residential_complex' = 'address.residential_complex',
  'adornment_address.street_name' = 'adornment_address.street_name',
  'address.building_type' = 'address.building_type',
  'address.street_name' = 'address.street_name',
  'address.building' = 'address.building',
  'address.section' = 'address.section',
  'address.entrance' = 'address.entrance',
  'address.apartment' = 'address.apartment',
  'address.geo_link' = 'address.geo_link',
  'address.is_center' = 'address.is_center',
  'address.newly_built' = 'address.newly_built'
}
export enum EstateType {
  HOUSE = 'house',
  APARTMENT = 'apartment'
}

export enum OfferType {
  RENT = 'rent',
  SALE = 'sale'
}

export enum PriceChange {
  UP = 'up',
  DOWN = 'down',
  EQUAL = 'equal'
}

export enum CurrencyCode {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR'
}

export enum PropertyStatus {
  ACTIVE = 'active',
  RENTED_BY_US = 'rented_by_us',
  RENTED_BY_OTHERS = 'rented_by_others'
}

export enum StreetType {
  STREET = 'street',
  AVENUE = 'avenue',
  SQUARE = 'square',
  BOULEVARD = 'boulevard',
  LANE = 'lane'
}

export enum PublicationStatus {
  UNPUBLISHED = 'unpublished',
  DO_NOT_PUBLISH = 'do_not_publish',
  PUBLISHED = 'published',
  POSTPONED = 'postponed',
  PUBLISHING = 'publishing'
}

export enum PublicationState {
  PENDING = 'pending',
  DONE = 'done',
  FAILED = 'failed',
  ARCHIVED = 'archived',
  POSTPONED = 'postponed'
}

export enum CityCode {
  LVIV = 'lv'
}

export enum RawFeaturesAlias {
  rooms = 'rooms',
  floor = 'floor',
  total_floors = 'total_floors',
  living_area = 'living_area',
  children = 'children',
  pets = 'pets'
}
export enum HeatingStatus {
  AUTONOMOUS = 'autonomous'
}

export enum ContactRole {
  UNKNOWN = 'unknown',
  AGENT = 'agent',
  NO_FEE_AGENT = 'no_fee_agent',
  OWNER = 'owner'
}

export enum Children {
  UNKNOWN = 'unknown',
  ALLOWED = 'allowed',
  NOT_ALLOWED = 'not_allowed',
  FROM_6_YEARS = 'from_6_years'
}

export enum Pets {
  UNKNOWN = 'unknown',
  ALLOWED = 'allowed',
  NOT_ALLOWED = 'not_allowed',
  ONLY_CATS = 'only_cats',
  ONLY_DOGS = 'only_dogs'
}

export enum PropertySourceType {
  OLX = 'olx',
  DOM_RIA = 'dom_ria',
  REAL_ESTATE_LVIV = 'real_estate_lviv',
  RIELTOR_UA = 'rieltor_ua',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  OTHER = 'other'
}

export interface ITableColumns<TItem> {
  title: string;
  dataIndex: string;
  key: string | string[];
  render?: (item: TItem, value: any, mutate: () => void) => any;
  width?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable?: boolean;
}

export interface BaseRecord {
  [key: string]: any;
}
export enum PropertyLabelsType {
  DUPLICATE = 'duplicate',
  PRICE_LOWER = 'price_lower',
  PRICE_HIGHER = 'price_higher',
  REVIVED_FROM_ARCHIVE = 'revived_from_archive',
  POSTPONED = 'postponed',
  AGENT = 'agent',
  OWNER = 'owner',
  MAYBE_OWNER = 'maybe_owner',
  TRASH = 'trash'
}

export enum EastType {
  APARTMENT = 'apartment',
  HOUSE = 'house'
}

export type EstateCity = {
  code: CityCode;
  name: string;
};

export type PropertyPublicationType = {
  channel: PropertySourceType;
  post_link: string;
  posted_at: string;
};

export interface RawItem {
  id: string;
  name: string;
}

export interface RawFeatures {
  id: string;
  alias: RawFeaturesAlias;
  value: string;
  created_at: string;
}

export interface NestedFeatures extends RawFeatures {
  feature: {
    id: string;
    alias: RawFeaturesAlias;
  };
}

interface IPhoneNumber {
  id: string;
  phone_number: string;
  linked: boolean;
}

export interface RawEstateContacts extends RawItem {
  role: ContactRole;
  phone_numbers: IPhoneNumber[];
  comment: string | null;
}

export interface PropertyAdmin {
  id: string;
  first_name: string;
  last_name: string;
}

export interface PropertyAssigner {
  assignee_id: string;
  first_name: string;
  last_name: string;
}

export type PropertyLabels = {
  id: string;
  label: {
    alias: PropertyLabelsType;
    name: string;
  };
  comment: string;
};

export interface RawPrice {
  amount: string;
  currency_code: CurrencyCode;
}

export interface PropertyPrice {
  original: RawPrice;
  converted: RawPrice;
}

export interface EstateSource {
  id: string;
  link: string | null;
  type: PropertySourceType;
  created_at: string;
  posted_at: string | null;
  primary: boolean;
  source: {
    name: string;
    type: PropertySourceType;
  };
  source_id: string;
}

export interface EstateAddress {
  apartment: string | null;
  building: string;
  building_type: RawItem | null;
  city: EstateCity | null;
  district: RawItem | null;
  entrance: string | null;
  geo_link: string | null;
  id: string;
  is_center: boolean;
  newly_built: 0 | 1;
  residential_complex: RawItem | null;
  section: string | null;
  street_name: string;
  street_type: StreetType;
  building_types: RawItem | null;
}

export interface IStreet {
  city_code: CityCode;
  street_type: StreetType;
  street_name: string;
}

export interface ISelectItem {
  label: string;
  value: string;
}

export interface PropertyItemType {
  id: string;
  title: string;
  estate_type: EstateType;
  offer_type: OfferType;
  price: PropertyPrice;
  currency_code: CurrencyCode;
  status: PropertyStatus;
  publication_status: PublicationStatus;
  last_time_contacted_at: string | null;
  created_at: string;
  address: EstateAddress;
  contacts: RawEstateContacts[];
  labels: PropertyLabels[];
  media: string[];
  latest_publication: PropertyPublicationType;
  comment: string;
  has_pending_uploads: boolean;
  author: PropertyAdmin;
}

export interface PropertyListItemType extends PropertyItemType {
  features: RawFeatures[];
  assignee: PropertyAdmin | null;
  source: EstateSource;
}

export type HandleError = {
  success: boolean;
  message: string;
};

export type ErrorResponseMessages = Record<string, string[]>;

export interface PropertySingleItemType extends PropertyItemType {
  features: NestedFeatures[];
  assignee: IAdmin;
  closed_at?: string;
  callback_at?: string;
  sources: EstateSource[];
}

export interface ResponseWithPagination<T> {
  data: T;
  cursor: {
    next: string | null;
    prev: string | null;
    hasMore: boolean;
  };
}

export interface IAdmin extends PropertyAdmin {
  role: {
    id: '1' | '2' | '3';
    name: string;
  };
  email: string;
  active: boolean;
}

export interface IPolicies {
  can_edit: boolean;
  can_change_active_status: boolean;
  can_reset_password: boolean;
}

export interface IListAdmin extends IAdmin {
  created_at: string;
  cities: EstateCity[];
  policies: IPolicies;
}

export interface IPublication {
  id: string;
  post_link: string;
  state: string;
  is_original: boolean;
  posted_at: string;
  scheduled_at: string;
  created_at: string;
  policies: {
    can_delete: string;
  };
}

export interface ILabelListItem extends RawItem {
  alias: PropertyLabelsType;
  requires_comment: boolean;
  comment: string;
}

export interface IPropertyMedia {
  uuid: string;
  url: string;
}

export interface IPropertyMediaResponse {
  has_pending_uploads: boolean;
  media: IPropertyMedia[];
}

export const adminRoles = [
  {
    label: 'Суперадмін',
    value: '1'
  },
  {
    label: 'Менеджер',
    value: '2'
  },
  {
    label: 'Скаут',
    value: '3'
  }
];
