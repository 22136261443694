import React, {useCallback, useMemo, useState} from 'react';
import {BaseRecord, FormFields, RawItem} from 'types';
import {useQuery} from 'react-query';
import {debounce} from 'helpers';
import AutocompleteInput from '../autocompleteInput';
import classNames from 'classnames';
import styles from '../widgets/styles.module.scss';
import {Grid} from '@mui/material';
import {filterFields} from '../../../mock';

type AutocompleteWithQueryProps = {
  control: any;
  register: any;
  fieldName: FormFields;
  queryKey: string;
  gridSize: number;
  multiple: boolean;
  className?: string;
  rules?: Record<string, any>;
};

const AutocompleteWithQuery = <TResponse extends BaseRecord = any>({
  control,
  register,
  fieldName,
  queryKey,
  queryFn,
  gridSize = 6,
  className = '',
  multiple = false,
  rules = {}
}: AutocompleteWithQueryProps & {queryFn: (arg: Record<string, any>) => TResponse | Promise<TResponse>}) => {
  const [name, setName] = useState<null | string>(null);

  const {data, isLoading} = useQuery<TResponse>([queryKey, name], () => queryFn({name: name || ''}), {
    retry: false,
    refetchOnMount: false,
    enabled: name !== null
  });

  const onChange = useCallback(
    debounce((name: string, value: string) => {
      Array.isArray(value) ? setName('') : setName(value);
    }, 300),
    []
  );

  const onFocus = () => {
    if (name !== null) {
      return;
    }
    setName('');
  };

  const items = useMemo(() => {
    return !!data && data?.length
      ? data.map((item: RawItem) => ({
          label: item.name,
          value: item.id
        }))
      : [];
  }, [data]);

  //@ts-ignore
  const config: Record<string, any> = filterFields[fieldName];

  return (
    <Grid item xs={gridSize} className={classNames(styles.row, className)}>
      <AutocompleteInput
        label={config.label}
        name={fieldName}
        onChange={onChange}
        items={items}
        control={control}
        register={register}
        placeholder={config.placeholder}
        size={'medium'}
        multiple={multiple}
        onInputFocus={onFocus}
        isLoading={isLoading}
        rules={rules}
      />
    </Grid>
  );
};

export default AutocompleteWithQuery;
